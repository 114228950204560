import {
  COMPONENT,
  DeviceType,
  DeviceWidth,
  ELLIPSE,
  GROUP,
  IMAGE,
  LAYOUT_SECTION,
  LIST,
  SECTION,
} from '@adalo/constants'

import {
  getId,
  getObject,
  removeChildren,
  update,
  remove,
  subPath,
  pathLength,
  nextPath,
  deepMap,
  remapSiblings,
  getDeviceType,
} from '@adalo/utils'

import { resetLibraryBindingIds } from 'utils/libraries'
import { getSnapValue } from 'utils/snapping'
import { unScale } from 'utils/zoom'
import {
  getDist,
  getAbsoluteBbox,
  selectObjectFromMouseCoords,
} from 'utils/geometry'
import { saveTouched } from 'utils/saving'
import {
  getParentIds,
  changeObjectParent,
  isShared,
  hasDevicePosition,
  CONTAINER_TYPES,
} from 'utils/positioning'
import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'
import getDeviceObject from 'utils/getDeviceObject'
import {
  changeActionIds,
  changeFilterIds,
  changeFormulaIds,
} from 'utils/actions'
import { changeChildIds } from 'utils/copying'
import resizeParent from 'utils/operations/shouldResizeParent'
import updateParentBounds from 'utils/operations/updateParentBounds'
import translateChildren from 'utils/operations/translateChildren'
import { rewriteSelection } from 'utils/selection'
import {
  isEditableSectionElement,
  shouldReparentComponent,
} from 'utils/layoutSections'

import { getApp } from 'ducks/apps'
import { performCreate, getParentId } from './objects'
import {
  applyInstructions,
  disableDeviceSpecificCustomListColumns,
  disableDeviceSpecificLayout,
  enableDeviceSpecificLayout,
  moveElement,
  moveScreen,
  resizeElement,
  resizeScreen,
  toggleFixedPosition,
  updateElementMargins,
} from './instructions'
import calculatePushGraphs from './pushing/calculatePushGraphs'

export const DEVICE_TYPES = ['desktop', 'tablet', 'mobile']

export const BEGIN_DRAG = Symbol('BEGIN_DRAG')
export const END_DRAG = Symbol('END_DRAG')
export const DRAG = Symbol('DRAG')

export default (state, action) => {
  if (action.type === BEGIN_DRAG) {
    const { position } = action

    return {
      ...state,
      positioningStartPoint: position,
      parentSelection: [],
    }
  }

  if (action.type === DRAG) {
    let {
      list,
      map,
      typeIndex,
      zoom,
      selection,
      positioningObjects,
      positioningStartPoint,
      positioningConstraint,
      magicLayout,
    } = state

    // `displaySelection` is the selection of objects we're displaying on the canvas
    let displaySelection = selection
    // `selection` is **now** the selection of objects we're processing
    selection = rewriteSelection(list, map, selection)

    const { position, shiftPressed, altPressed, mouseCoords, globalState } =
      action

    const app = getApp(globalState, state.appId)

    if (!positioningObjects && getDist(position, positioningStartPoint) >= 5) {
      positioningObjects = {}
      let paths = removeChildren(selection.map(id => map[id]))

      const ids = paths.map(path => {
        return getObject(state.list, path).id
      })

      if (altPressed) {
        const newSelection = []
        // Copy all the objects

        for (const id of ids) {
          const path = state.map[id]
          let obj = getObject(list, path)
          const parentId = getObject(list, subPath(path, 1)).id
          const newId = getId()
          const mobileOnly = app?.webSettings?.layoutMode === 'mobile'

          obj = changeChildIds(obj, { id: newId })
          obj = resetLibraryBindingIds(obj)
          obj = changeActionIds(obj)
          obj = changeFormulaIds(obj, null, true)
          obj = changeFilterIds(obj)

          const [newState] = performCreate(
            state,
            nextPath(path),
            obj,
            newId,
            parentId,
            true,
            true,
            undefined,
            undefined,
            true, //Only include initial device if Auto Custom Layout is on
            mobileOnly
          )

          state = newState
          newSelection.push(newId)
          list = state.list
          map = state.map
          typeIndex = state.typeIndex
        }

        // We've created copies of the objects in the original `selection`
        // so we need to update the `displaySelection` to reflect the new objects on the canvas
        displaySelection = newSelection
        paths = displaySelection.map(id => map[id])
      }

      for (const path of paths) {
        const obj = getObject(list, path)

        if (obj && paths.includes(path)) {
          positioningObjects[obj.id] = [obj.x, obj.y]

          const component = getParentScreen(list, map, obj.id)
          const deviceType = component
            ? getDeviceType(component.width)
            : 'mobile'

          const { x: currentX, y: currentY } = getDeviceObject(obj, deviceType)

          if (hasDevicePosition(obj, deviceType)) {
            positioningObjects[obj.id] = [currentX, currentY, obj.x, obj.y]
          }

          for (const device of DEVICE_TYPES) {
            if (hasDevicePosition(obj, device)) {
              positioningObjects[obj.id].push({
                [device]: { x: obj[device].x, y: obj[device].y },
              })
            }
          }
        }
      }
    }

    let currentXSnap = null
    let currentYSnap = null

    const parentSelection = []
    let currentHoverScreen = null

    if (positioningObjects) {
      const [startX, startY] = unScale(positioningStartPoint, zoom)
      const [newX, newY] = unScale(position, zoom)

      let diffX = Math.round(newX - startX)
      let diffY = Math.round(newY - startY)

      currentHoverScreen = list.find(item => {
        if (item.type !== COMPONENT) {
          return false
        }

        const { x, y, width, height } = item

        return newX >= x && newX <= x + width && newY >= y && newY <= y + height
      })

      let xCoords = {}
      let yCoords = {}

      // Snapping
      for (const id of Object.keys(positioningObjects)) {
        const obj = getDeviceObject(getObject(state.list, map[id]))

        if (!obj) continue

        const component = getObject(state.list, subPath(map[id], 1))

        let [originalX, originalY] = positioningObjects[id]

        if (obj?.type !== COMPONENT) {
          originalX += component.x
          originalY += component.y
        }

        xCoords = {
          ...xCoords,
          [`${id}.left`]: originalX + diffX,
          [`${id}.right`]: originalX + obj?.width + diffX,
          [`${id}.center`]: originalX + obj?.width / 2 + diffX,
        }

        yCoords = {
          ...yCoords,
          [`${id}.top`]: originalY + diffY,
          [`${id}.bottom`]: originalY + obj.height + diffY,
          [`${id}.center`]: originalY + obj.height / 2 + diffY,
        }
      }

      const xSnap = getSnapValue(state.xGrid, xCoords, state.zoom)
      const ySnap = getSnapValue(state.yGrid, yCoords, state.zoom)

      if (xSnap) {
        const key = Object.keys(xSnap)[0]
        currentXSnap = xSnap[key]
        diffX += currentXSnap - xCoords[key]
      }

      if (ySnap) {
        const key = Object.keys(ySnap)[0]
        currentYSnap = ySnap[key]
        diffY += currentYSnap - yCoords[key]
      }

      // Shift
      if (shiftPressed && !positioningConstraint) {
        positioningConstraint = Math.abs(diffX) > Math.abs(diffY) ? 'x' : 'y'
      } else if (!shiftPressed && positioningConstraint) {
        positioningConstraint = null
      }

      if (positioningConstraint === 'x') {
        diffY = 0
        currentYSnap = null
      } else if (positioningConstraint === 'y') {
        diffX = 0
        currentXSnap = null
      }

      if (magicLayout) {
        const instructions = []
        for (const objectId of Object.keys(positioningObjects)) {
          const path = map[objectId]
          const obj = getObject(list, path)

          const { type } = obj

          const [originalX, originalY] = positioningObjects[objectId]

          const x = originalX + diffX
          const y = originalY + diffY

          const currentObjectScreen = getParentScreen(list, map, obj.id)
          const component = currentHoverScreen ?? currentObjectScreen

          if (component) {
            let calculationX = x
            let calculationY = y

            // translate the object to the new position based on the screen
            if (component.id !== currentObjectScreen.id) {
              const { x: parentX, y: parentY } = currentObjectScreen
              const { x: componentX, y: componentY } = component
              calculationX -= componentX - parentX
              calculationY -= componentY - parentY
            }

            const deviceType = getDeviceType(component.width)

            const possibleParent = component.children.find(child => {
              // TODO(michael-adalo): when we support multi-container Sections
              // we should probably drill into the children of the section to find the possible parent
              // instead of just checking if the section is the parent
              // The logic within src/components/Editor/Canvas/HoverSelection/index.js should be updated
              // from Section > Container to Container > Section to determine which elements to show the parentSelection on

              // it is not possible to reparent a section component onto anything aside from a screen
              if (isEditableSectionElement(obj)) {
                return false
              }

              let invalidParentComponent = !CONTAINER_TYPES.includes(child.type)

              if (isEditableSectionElement(child)) {
                invalidParentComponent = false
              }

              const isComponentHidden =
                child.deviceVisibility && !child.deviceVisibility[deviceType]

              if (invalidParentComponent || isComponentHidden) {
                return false
              }

              const {
                x: parentX,
                y: parentY,
                width: parentW,
                height: parentH,
              } = getDeviceObject(child, deviceType)

              const parentMeasures = {
                x: parentX,
                y: parentY,
                width: parentW,
                height: parentH,
              }

              const insideAxisX =
                parentMeasures.x <= calculationX &&
                parentMeasures.x + parentMeasures.width >=
                  calculationX + obj.width

              const insideAxisY =
                parentMeasures.y <= calculationY &&
                parentMeasures.y + parentMeasures.height >=
                  calculationY + obj.height

              if (mouseCoords?.mouseX && mouseCoords?.mouseY) {
                child = {
                  id: child.id,
                  width: child.width,
                  height: child.height,
                  x: child.x + component.x,
                  y: child.y + component.y,
                }

                const isMouseInsideParentBounds = selectObjectFromMouseCoords(
                  child,
                  zoom,
                  mouseCoords
                )

                return isMouseInsideParentBounds && child.id !== objectId
              } else {
                return insideAxisX && insideAxisY && child.id !== objectId
              }
            })

            if (possibleParent) {
              parentSelection.push(possibleParent.id)
            }
          }

          if (type === COMPONENT) {
            instructions.push(moveScreen(objectId, x, y))
          } else {
            const device = getDeviceType(component.width)
            if (device !== obj.initialDevice) {
              instructions.push(enableDeviceSpecificLayout(obj.id, device))
            }
            instructions.push(moveElement(objectId, x, y))
          }
        }

        ;({ list } = applyInstructions({ list, pathMap: map }, instructions))
      } else {
        for (const id of Object.keys(positioningObjects)) {
          const path = map[id]
          const [originalX, originalY] = positioningObjects[id]
          const originalObject = getObject(list, path)

          if (!originalObject) {
            continue
          }

          const x = originalX + diffX
          const y = originalY + diffY

          let newObject = { ...originalObject, x, y }

          const component = getParentScreen(list, map, originalObject.id)
          let deviceType = null

          if (newObject.type !== COMPONENT) {
            deviceType = getDeviceType(component.width)

            if (!isShared(originalObject, deviceType)) {
              newObject[deviceType] = { ...newObject[deviceType], x, y }
            } else {
              newObject = updateSharedDevices(
                id,
                positioningObjects,
                diffX,
                diffY,
                originalObject,
                newObject
              )

              if (hasDevicePosition(originalObject, deviceType)) {
                newObject.x = positioningObjects[id][2] + diffX
                newObject.y = positioningObjects[id][3] + diffY
              }
              deviceType = null
            }
            const updateShared = isShared(newObject, deviceType)

            newObject = translateChildren(
              newObject,
              originalObject,
              deviceType,
              updateShared
            )
          }

          list = update(list, path, newObject)
          list = updateParentBounds(
            list,
            state.map,
            id,
            null,
            resizeParent,
            deviceType,
            magicLayout
          )
        }
      }
    }

    return {
      ...state,
      list,
      map,
      typeIndex,
      selection: displaySelection,
      currentXSnap: { coord: currentXSnap },
      currentYSnap: { coord: currentYSnap },
      positioningObjects,
      positioningConstraint,
      textEditing: false,
      shapeEditing: false,
      parentSelection,
    }
  }

  if (action.type === END_DRAG) {
    /**
     * @type {{
     *   list: import('./types/ObjectList').ObjectList,
     *   map: import('./types/ObjectPathMap').ObjectPathMap
     * }}
     */
    let {
      appId,
      list,
      map,
      typeIndex,
      positioningObjects,
      zoom,
      magicLayout,
      selection,
    } = state

    const { mouseCoords } = action

    if (positioningObjects) {
      const ids = Object.keys(positioningObjects)
      let paths = removeChildren(ids.map(id => map[id]))

      const componentIds = paths.map(
        path => getObject(list, subPath(path, 1))?.id
      )

      paths = paths.filter(path => pathLength(path) > 1)
      const reducedIds = paths.map(path => getObject(list, path).id)

      const parentTypes = [LIST]
      if (magicLayout) {
        parentTypes.push(SECTION, IMAGE, ELLIPSE, GROUP)
      }

      for (const id of reducedIds) {
        const path = map[id]
        const object = getObject(list, path)

        delete object.possibleParent

        const component = getParentScreen(list, map, id)
        const deviceType = component ? getDeviceType(component.width) : 'mobile'

        const absoluteBbox = getAbsoluteBbox(object, list, map, deviceType)

        const newParentId = getParentId(
          list,
          map,
          typeIndex,
          parentTypes,
          {
            ...absoluteBbox,
            id,
            magicLayout,
          },
          mouseCoords,
          zoom
        )

        if (!newParentId) {
          list = remove(list, path)
          map = remapSiblings(list, map, path)
          selection = selection.filter(id => id !== object.id)
        } else {
          const newComponent = getObject(list, subPath(map[newParentId], 1))
          const oldComponent = getObject(list, subPath(path, 1))

          const xDiff = newComponent.x - oldComponent.x
          const yDiff = newComponent.y - oldComponent.y

          const currentParentIds = getParentIds(
            list,
            map,
            object.id,
            magicLayout
          )
          const currentParentId = currentParentIds.slice(-1)[0] || null

          const newComponentDeviceType = getDeviceType(newComponent.width)

          if (newParentId !== currentParentId) {
            let newObject = magicLayout
              ? object
              : deepMap([object], obj => ({
                  ...obj,
                  x: obj.x - xDiff,
                  y: obj.y - yDiff,
                }))[0]

            newObject = changeFormulaIds(newObject, newParentId)

            list = update(list, path, newObject)

            const parentPath = map[newParentId]
            const parent = parentPath && getObject(list, parentPath)

            if (magicLayout) {
              if (parent && !parent.children) {
                // Some containers, like rectangles, may not have a children array initialized so set it here.
                const newParent = {
                  ...parent,
                  children: [],
                }

                list = update(list, parentPath, newParent)
              }

              const newComponentDeviceType = getDeviceType(newComponent.width)

              if (
                object.type !== COMPONENT &&
                newComponent?.id !== oldComponent?.id
              ) {
                const { width, height } = getDeviceObject(newObject, deviceType)
                const { finalX, finalY } = getFinalDeviceProperties(
                  newObject,
                  deviceType,
                  xDiff,
                  yDiff
                )

                const instructions = []
                if (newComponentDeviceType) {
                  // If we're on a new device, set that as initialDevice instead of running the enableDeviceSpecificLayout instruction
                  newObject.initialDevice = newComponentDeviceType
                } else if (deviceType !== newObject.initialDevice) {
                  // With Auto Custom Layout on, before a layout change in a device different from the initial one happens, we enable device-specific layout in that device
                  instructions.push(enableDeviceSpecificLayout(id, deviceType))
                }

                instructions.push(resizeElement(id, width, height))
                instructions.push(moveElement(id, finalX, finalY))
                ;({ list } = applyInstructions(
                  {
                    list,
                    pathMap: map,
                  },
                  instructions
                ))
              }
            }

            const shouldReparent = shouldReparentComponent(object, parent)

            ;[list, map] = changeObjectParent(
              list,
              map,
              object.id,
              shouldReparent
                ? newParentId
                : getParentScreen(list, map, newParentId)?.id
            )

            const updatedObject = getObject(list, map[object.id])
            const updatedParentScreen = getParentScreen(
              list,
              map,
              updatedObject.id
            )
            const updatedParentScreenDeviceType = getDeviceType(
              updatedParentScreen.width
            )

            if (magicLayout && shouldReparent === true) {
              list = calculatePushGraphs(list, map, newComponent.id)

              // TODO (michael-adalo): re-consider this logic when moving logic here into an Instruction
              const { x, y, width, height } = getDeviceObject(
                updatedObject,
                deviceType
              )

              /** @type {Array<Instruction>} */
              const instructions = []

              if (updatedObject.type !== LAYOUT_SECTION) {
                for (const device of Object.values(DeviceType)) {
                  instructions.push(
                    disableDeviceSpecificLayout(updatedObject.id, device)
                  )
                }
              }

              instructions.push(updateElementMargins(updatedObject.id))

              if (updatedObject.type === LIST) {
                instructions.push(disableDeviceSpecificCustomListColumns(updatedObject.id)) // prettier-ignore
              }

              if (updatedObject.type !== LAYOUT_SECTION) {
                if (newComponentDeviceType) {
                  // If we're on a new device, set that as initialDevice instead of running the enableDeviceSpecificLayout instruction
                  newObject.initialDevice = newComponentDeviceType
                } else if (deviceType !== newObject.initialDevice) {
                  // With Auto Custom Layout on, before a layout change in a device different from the initial one happens, we enable device-specific layout in that device
                  instructions.push(enableDeviceSpecificLayout(id, deviceType))
                }
                instructions.push(toggleFixedPosition(updatedObject.id, false))
              }

              instructions.push(moveElement(updatedObject.id, x, y))

              if (updatedObject.type === LAYOUT_SECTION) {
                const deviceWidths = {
                  [DeviceType.DESKTOP]: DeviceWidth.DESKTOP_DEFAULT_WIDTH,
                  [DeviceType.TABLET]: DeviceWidth.TABLET_DEFAULT_WIDTH,
                  [DeviceType.MOBILE]: DeviceWidth.MOBILE_DEFAULT_WIDTH,
                }
                for (const device of Object.values(DeviceType)) {
                  const { height } = getDeviceObject(updatedObject, device)
                  instructions.push(
                    resizeScreen(updatedParentScreen.id, deviceWidths[device]),
                    moveElement(updatedObject.id, 0, y),
                    resizeElement(
                      updatedObject.id,
                      deviceWidths[device],
                      height
                    )
                  )
                }

                const { width } = updatedParentScreen
                const { height } = getDeviceObject(
                  updatedObject,
                  updatedParentScreenDeviceType
                )
                instructions.push(
                  resizeScreen(updatedParentScreen.id, width),
                  moveElement(updatedObject.id, 0, y),
                  resizeElement(updatedObject.id, width, height)
                )
              } else {
                instructions.push(resizeElement(updatedObject.id, width, height)) // prettier-ignore
              }
              ;({ list } = applyInstructions(
                { list, pathMap: map },
                instructions
              ))
            }
          }
        }
      }

      saveTouched(
        appId,
        list,
        map,
        Object.keys(positioningObjects).concat(componentIds)
      )
    }

    return {
      ...state,
      map,
      list,
      selection: [...selection],
      positioningObjects: null,
      positioningStartPoint: null,
      parentSelection: [],
    }
  }

  return state
}

// Actions

export const beginDrag = position => ({
  type: BEGIN_DRAG,
  position,
})

export const drag =
  (position, shiftPressed, altPressed, mouseCoords) => (dispatch, getState) =>
    dispatch({
      type: DRAG,
      position,
      shiftPressed,
      altPressed,
      mouseCoords,
      globalState: getState(),
    })

export const endDrag =
  (mouseCoords = {}) =>
  dispatch =>
    dispatch({
      type: END_DRAG,
      mouseCoords,
    })

// Selectors

export const getXGrid = state => {
  return state.editor.objects.present.xGrid
}

export const getYGrid = state => {
  return state.editor.objects.present.yGrid
}

export const getDragging = state => {
  return !!state.editor.objects.present.positioningStartPoint
}

const updateSharedDevices = (
  id,
  positioningObjects,
  diffX,
  diffY,
  originalObject,
  newObject
) => {
  for (const device of DEVICE_TYPES) {
    if (
      isShared(originalObject, device) &&
      hasDevicePosition(originalObject, device)
    ) {
      const originalDeviceProperties = positioningObjects[id].find(
        value => Object.keys(value)[0] === device
      )

      const { x: originalDeviceX, y: originalDeviceY } =
        originalDeviceProperties[device] || {}

      newObject[device].x = originalDeviceX + diffX
      newObject[device].y = originalDeviceY + diffY
    }
  }

  return newObject
}

const getFinalDeviceProperties = (object, device, xDiff, yDiff) => {
  let { x: finalX, y: finalY } = { ...object }
  const { x: deviceX, y: deviceY } = getDeviceObject(object, device)

  finalX -= xDiff
  finalY -= yDiff

  if (object[device]) {
    finalX = deviceX - xDiff
    finalY = deviceY - yDiff
  }

  return { finalX, finalY }
}
