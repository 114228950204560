import {
  COMPONENT,
  GROUP,
  responsivePositioningOptions,
} from '@adalo/constants'
import { getId } from '@adalo/utils'
import {
  getDefaultResponsivity,
  isActionButton,
} from 'utils/defaultConstraints'
import { defaults } from 'utils/objects'
import { createName } from 'utils/naming'
import { EditorObject } from 'utils/responsiveTypes'
import { getDefaultLayoutForResponsivity } from 'utils/defaultLayout'
import { ObjectList } from '../../types/ObjectList'
import { ObjectPathMap } from '../../types/ObjectPathMap'

const { CENTER } = responsivePositioningOptions

const createEmptyObject = (
  list: ObjectList,
  pathMap: ObjectPathMap,
  obj: EditorObject,
  setId = true,
  mobileOnly = false
): EditorObject => {
  const responsivity = getDefaultResponsivity(obj)
  const layout = getDefaultLayoutForResponsivity(responsivity)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const newObj: EditorObject = {
    ...layout,
    ...defaults[obj.type], // above lint rule applies to this
    responsivity,
    ...obj,
    name: createName(obj.type, obj['name'], list),
    id: setId ? (getId() as string) : obj.id,
  }

  if (mobileOnly && !isActionButton(obj) && newObj.responsivity) {
    newObj.responsivity.horizontalPositioning = CENTER
  }

  for (const symbol of Object.getOwnPropertySymbols(newObj)) {
    delete newObj[symbol]
  }

  if (newObj.type !== GROUP && newObj.type !== COMPONENT) {
    newObj.x = newObj.x || 0
    newObj.y = newObj.y || 0
  }

  return newObj
}

export default createEmptyObject
