import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'

import './TabBar.css'

const TabBar = props => {
  const { extraProps, tabs, location, history, initialActiveTab } = props
  const { appId } = extraProps

  const storageKey = `lastTab-${appId}`

  const [activeTab, setActiveTab] = useState(tabs[0])

  const setActiveTabWithPersistence = useCallback(
    tab => {
      localStorage.setItem(storageKey, tab.id)
      setActiveTab(tab)
    },
    [storageKey]
  )

  useEffect(() => {
    const routeTab = tabs.find(tab => location.pathname.endsWith(tab.id))

    if (routeTab) {
      return setActiveTabWithPersistence(routeTab)
    }

    if (storageKey in localStorage) {
      const storageTab = localStorage.getItem(storageKey)
      const lastTab = tabs.find(tab => tab.id === storageTab)

      if (lastTab) {
        return setActiveTabWithPersistence(lastTab)
      }
    }

    const initialTab = tabs.find(tab => tab.id === initialActiveTab)

    if (initialTab) {
      return setActiveTabWithPersistence(initialTab)
    }

    return setActiveTabWithPersistence(tabs[0])
  }, [tabs, location, initialActiveTab, storageKey])

  const handleClick = id => {
    history.push(`/apps/${appId}/screens/add/${id}`)
    localStorage.setItem(storageKey, id)
  }

  return (
    <>
      <div className="editor-add-panel-tabs">
        {tabs.map(({ id, title }) => (
          <div
            key={id}
            className={classNames('editor-add-panel-tab', {
              'editor-add-panel-tab-active': activeTab?.id === id,
            })}
            onClick={() => handleClick(id)}
          >
            {title}
          </div>
        ))}
      </div>
      {activeTab ? <activeTab.component {...extraProps} /> : null}
    </>
  )
}

export default withRouter(TabBar)
