import * as Sentry from '@sentry/browser'
import qs from 'qs'

import type { DefaultRootState } from 'react-redux'

import { WebSettings } from 'ducks/apps/App'

import { restartDeveloperSocket } from 'utils/developers'
import { initializeUserflow } from 'utils/userflow'
import { storeMakerToken } from 'utils/auth'
import { adaloBackendAxios, AxiosResponse } from 'utils/io/http/axios'

const SET_CURRENT_USER = Symbol('SET_CURRENT_USER')
const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
const SET_CURRENT_USER_TOKEN = Symbol('SET_CURRENT_USER_TOKEN')
const SET_AUTH_VISIBLE = Symbol('SET_AUTH_VISIBLE')
const REQUEST_USERS = 'REQUEST_USERS'
const REQUEST_USERS_FULFILLED = `${REQUEST_USERS}_FULFILLED`
const UPDATE_CURRENT_USER_FULFILLED = `${UPDATE_CURRENT_USER}_FULFILLED`
const VALIDATE_EMAIL = 'VALIDATE_EMAIL'

export type CurrentUser = User | null
type Users = User[]
type UserToken = string | null

export interface UserState extends DefaultRootState {
  users: UserInfo
}

interface UserInfo {
  users: Users
  currentUser: CurrentUser
  currentUserToken: UserToken
  authVisible: boolean
}

export interface User {
  id: number
  admin: boolean
  name: string
  email: string
  developer: boolean
  createdAt: string
  Organizations: Organization[]
  OrganizationUser: { isAdmin: boolean }
  persona: string
  client: boolean
  emailValidatedAt?: Date
  emailValidationString?: string
}

export interface Organization {
  id: string
  Users: User[]
}
/**
 * Unsure why we have this custom action interface,
 * but because of this we need to define the fulfilled action type manually
 */

interface Action {
  type:
    | typeof SET_CURRENT_USER
    | typeof SET_CURRENT_USER_TOKEN
    | typeof SET_AUTH_VISIBLE
    | typeof REQUEST_USERS
    | typeof UPDATE_CURRENT_USER
    | typeof REQUEST_USERS_FULFILLED
    | typeof UPDATE_CURRENT_USER_FULFILLED
  userObject?: User
  token?: string
  payload?: Promise<AxiosResponse<UserInfo>>
}

interface ActionResponse extends Omit<Action, 'payload'> {
  payload: {
    data: Users
  }
}

const INITIAL_STATE = {
  users: [],
  currentUser: null,
  currentUserToken: null,
  authVisible: false,
}

// Reducer

// TODO(cameron) remove
export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = INITIAL_STATE,
  action: ActionResponse
): UserInfo | void => {
  if (action.type === SET_CURRENT_USER) {
    const { userObject } = action

    if (!userObject) {
      return
    }

    window.adaloDeveloper = userObject.developer

    // Setup analytics tools
    Sentry.configureScope(scope => {
      scope.setUser({ email: userObject.email })
    })

    initializeUserflow(userObject).catch(err =>
      console.error('FAILED TO INITIALIZE USERFLOW:', err)
    )

    return {
      ...state,
      currentUser: userObject,
      authVisible: false,
    }
  }

  if (action.type === SET_CURRENT_USER_TOKEN) {
    const { token } = action

    if (!token) {
      return
    }

    storeMakerToken(token)

    restartDeveloperSocket(token)

    return {
      ...state,
      currentUserToken: token,
      authVisible: false,
    }
  }

  if (action.type === SET_AUTH_VISIBLE) {
    return {
      ...state,
      authVisible: true,
    }
  }

  if (action.type === `${REQUEST_USERS}_FULFILLED`) {
    return {
      ...state,
      users: action.payload.data,
    }
  }

  if (action.type === `${UPDATE_CURRENT_USER}_FULFILLED`) {
    const currentUser = action.payload.data[0] || null

    return {
      ...state,
      currentUser,
    }
  }

  return state
}

// Actions

export const setCurrentUser = (userObject: User): Action => ({
  type: SET_CURRENT_USER,
  userObject,
})

interface UpdateUserFields extends Omit<User, 'client'> {
  client: string
  appCategory: string
  dataOption: string
  appLayoutMode: WebSettings['layoutMode']
}

export const updateCurrentUser = (
  userId: number,
  fields: Partial<UpdateUserFields>
): Action => ({
  type: UPDATE_CURRENT_USER,
  payload: adaloBackendAxios.put(`/users/${userId}`, fields),
})

export const setCurrentUserToken = (token: string): Action => ({
  type: SET_CURRENT_USER_TOKEN,
  token,
})

export const setAuthVisible = (): Action => ({
  type: SET_AUTH_VISIBLE,
})

export const requestUsers = (opts?: { query: string }): Action => ({
  type: REQUEST_USERS,
  payload: adaloBackendAxios.get(`/users?${qs.stringify(opts)}`),
})

export const validateUserEmail = (opts: {
  email: string
  code: string
  callback: () => void
}): Action => {
  const payload = adaloBackendAxios
    .post('/users/validate-email', opts)
    .then(res => {
      opts.callback()

      return res
    })

  return {
    type: VALIDATE_EMAIL,
    payload,
  }
}

// Selectors

export const getCurrentUser = (state: UserState): CurrentUser => {
  return state.users.currentUser
}

export const getCurrentUserToken = (state: UserState): UserToken => {
  return state.users.currentUserToken
}

export const getAuthVisible = (state: UserState): boolean => {
  return state.users.authVisible
}

export const getUsers = (state: UserState): Users => {
  return state.users.users
}

export const userIsAdmin = (
  organization: Organization,
  currentUser: User
): boolean => {
  if (!currentUser) {
    return false
  }

  const members = organization.Users
  const user = members.filter(u => u.id === currentUser.id)[0]

  return user?.OrganizationUser?.isAdmin || currentUser.admin
}
