import React, { Component } from 'react'
import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { deepMerge } from '@adalo/utils'
import { ACTION, dataTypes } from '@adalo/constants'

import { getComponentInfo, evaluateEnabled } from 'utils/libraries'
import { getAppLibraryVersion, getAppLibraries, getApp } from 'ducks/apps'

import {
  updateObject,
  getLibraryGlobals,
  setLibraryGlobals,
  resizeObject,
  getMagicLayout,
} from 'ducks/editor/objects'
import { LIBRARY_INSPECT_GROUP } from 'ducks/accordions'

import EmptyState from 'components/Shared/EmptyState'
import { GroupedAccordion } from 'components/Shared/Accordion'
import ToggleButton from 'components/Shared/Forms/ToggleButton'
import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'
import CalloutCard from 'components/Shared/CalloutCard'
import StylesAccordion from 'components/Shared/StylesAccordion'

import PropControl from './PropControl'
import SettingsPanel from './SettingsPanel'
import ComponentError from './ComponentError'
import HelpText from './HelpText'
import './LibraryInspect.scss'
import GenericInspectRow from '../GenericRow'

class LibraryInspectItem extends Component {
  static defaultProps = { libraryGlobals: {} }

  handleChange = (values, actionChanges, isGlobal) => {
    const { onChange } = this.props

    return onChange(values, actionChanges, isGlobal)
  }

  handleToggleEnabled = newValue => {
    const { namespace } = this.props

    // This is only relevant for children
    if (!namespace) {
      return
    }

    this.handleChange({ [namespace]: { enabled: newValue } })
  }

  hasEnabledProp = () => {
    const { config } = this.props
    const { props } = config

    if (props.filter(p => p.name === 'enabled').length > 0) {
      return true
    }

    return false
  }

  getDisabled = () => {
    const values = this.getValues()

    return this.hasEnabledProp() && !values.enabled
  }

  getValues() {
    const { values } = this.props

    return values || {}
  }

  getTitle = (isOpen = false, forceEnabled = false) => {
    const { config } = this.props

    const values = this.getValues()
    let title = config.displayName || config.name

    if (values.accordionTitle && typeof values.accordionTitle === 'string') {
      title = values.accordionTitle
    }

    const showEnabled =
      this.hasEnabledProp() && (forceEnabled || !values.enabled)

    return (
      <SectionTitle
        isOpen={isOpen}
        label={title}
        enabled={values.enabled}
        showEnabled={showEnabled}
        onToggleEnabled={this.handleToggleEnabled}
      />
    )
  }

  getExpandedTitle = () => {
    return this.getTitle(true, true)
  }

  accordionRef = el => {
    this._accordion = el
  }

  shouldDisplayProp = prop => {
    const values = this.getValues()

    return evaluateEnabled(prop.enabled, values) && !prop.hidden
  }

  renderSection = () => {
    const {
      config,
      object,
      objectHeight,
      objectWidth,
      namespace,
      libraryGlobals,
      mobileOnly,
      appId,
    } = this.props

    const values = this.getValues()
    let { props, displayName, role, reference, helpText, editStylesTitle } =
      config

    if (this.getDisabled()) {
      return <EmptyState>{displayName} is disabled</EmptyState>
    }

    props = props.filter(prop => prop.name !== 'enabled')

    const styleComponents = []
    const components = []
    let colorProps = []

    for (const prop of props) {
      if (!this.shouldDisplayProp(prop)) {
        continue
      }
      const inEditStyles = prop.style === true && prop.type !== ACTION

      const component = (
        <PropControl
          key={prop.name}
          prop={prop}
          onChange={this.handleChange}
          value={prop.global ? libraryGlobals[prop.name] : values[prop.name]}
          values={values}
          objectId={object.id}
          namespace={namespace}
          role={role || prop.role}
          reference={reference || prop.reference}
          objectWidth={objectWidth}
          objectHeight={objectHeight}
          objectName={object.componentName}
          mobileOnly={mobileOnly}
          appId={appId}
          topTitle={inEditStyles}
        />
      )

      if (inEditStyles) {
        if (prop.type === dataTypes.COLOR) {
          colorProps.push(component)
        } else {
          colorProps = []
        }

        if (colorProps.length === 2) {
          styleComponents.pop()
          const colorRow = (
            <GenericInspectRow className="default-font-control">
              {colorProps}
            </GenericInspectRow>
          )
          styleComponents.push(colorRow)
          colorProps = []
        } else {
          styleComponents.push(component)
        }
      } else {
        components.push(component)
      }
    }

    const styleType =
      typeof editStylesTitle === 'string' ? editStylesTitle : 'Edit'

    return (
      <div>
        {helpText ? <HelpText text={helpText} header /> : null}
        {components}
        {styleComponents.length > 0 && (
          <StylesAccordion
            renderChildren={() => styleComponents}
            title={`${styleType} Styles`}
          />
        )}
      </div>
    )
  }

  render() {
    const { namespace, isOnlyItem, object } = this.props

    if (!namespace && isOnlyItem) {
      return (
        <div className="library-non-accordion-items">
          {this.renderSection()}
        </div>
      )
    }

    const itemId = namespace ? `${object.id}-${namespace}` : `${object.id}-root`

    return (
      <GroupedAccordion
        defaultExpanded={!namespace}
        group={LIBRARY_INSPECT_GROUP}
        title={this.getTitle()}
        expandedTitle={this.getExpandedTitle()}
        className={classNames('library-inspect-accordion', {
          'library-inspect-accordion-disabled': this.getDisabled(),
        })}
        renderChildren={this.renderSection}
        itemId={itemId}
        object={object}
      />
    )
  }
}

class SectionTitle extends Component {
  handleClick = e => {
    const { isOpen } = this.props

    if (isOpen) {
      e.stopPropagation()
    }
  }

  render() {
    const { label, enabled, showEnabled, onToggleEnabled } = this.props

    return (
      <div className="library-inspect-section-title">
        <div className="library-inspect-title-text">{label}</div>
        <div onClick={this.handleClick}>
          {showEnabled ? (
            <ToggleButton value={enabled} onChange={onToggleEnabled} />
          ) : null}
        </div>
      </div>
    )
  }
}

class LibraryInspect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      objectId: null,
    }
  }

  componentDidMount() {
    const { object } = this.props

    if (object && object.id) {
      this.setState({ objectId: object.id })
    }
  }

  componentWillUnmount() {
    const { magicLayout, updateObject } = this.props
    const { objectId } = this.state

    if (objectId && magicLayout === true) {
      localStorage.removeItem(LIBRARY_INSPECT_GROUP)
      updateObject(objectId, {})
    }
  }

  componentDidUpdate(prevProps) {
    const { object } = this.props

    if (prevProps.object && object && prevProps.object.id !== object.id) {
      this.setState({ objectId: object.id })
    }
  }

  handleChange = (changes, actionChanges = {}, isGlobal = false) => {
    const { object, updateObject, setLibraryGlobals } = this.props

    if (isGlobal) {
      const { libraryName, componentName } = object

      return setLibraryGlobals(libraryName, componentName, changes)
    }

    const newObject = deepMerge(object.attributes, changes)

    updateObject(object.id, { attributes: newObject, actions: actionChanges })
  }

  getLibraryComponent = () => {
    const { object, libraryVersion } = this.props
    const { libraryName, componentName } = object

    return getComponentInfo(libraryName, libraryVersion, componentName)
  }

  render() {
    const {
      object,
      libraryGlobals,
      resizeObject,
      appLibraries,
      appId,
      mobileOnly,
    } = this.props

    const { libraryName, componentName } = object

    const info = this.getLibraryComponent()

    const library = appLibraries.find(l => l.name === libraryName)

    if (!info || !library?.licensed) {
      return <ComponentError library={library} appId={appId} object={object} />
    }

    return (
      <div className="library-inspect">
        {info.settingsURL && [
          <SettingsPanel
            key={`${libraryName}/${componentName}`}
            library={libraryName}
            object={object}
            settingsURL={info.settingsURL}
            height={info.settingsPanelHeight}
          />,
        ]}
        {[
          <React.Fragment key={object.id}>
            <LibraryInspectItem
              values={object.attributes}
              config={info}
              onChange={this.handleChange}
              object={object}
              isOnlyItem={(info.childComponents || []).length === 0}
              objectWidth={object.width}
              objectHeight={object.height}
              libraryGlobals={libraryGlobals}
              resizeObject={resizeObject}
              mobileOnly={mobileOnly}
              appId={appId}
            />
            {(info.childComponents || []).map(
              child =>
                evaluateEnabled(child.enabled, object.attributes) && (
                  <LibraryInspectItem
                    key={child.name}
                    config={child}
                    values={object.attributes[child.name]}
                    onChange={this.handleChange}
                    namespace={child.name}
                    object={object}
                    objectWidth={object.width}
                    objectHeight={object.height}
                    libraryGlobals={libraryGlobals[child.name]}
                    resizeObject={resizeObject}
                    mobileOnly={mobileOnly}
                    appId={appId}
                  />
                )
            )}
            {!['adalo', 'proton'].includes(library.name) &&
              library.author !== 'Adalo' && (
                <CalloutCard
                  className="library-contact-box"
                  borderStyle="solid"
                  color="lightTeal"
                  alignment="left"
                >
                  <Link
                    to={`/apps/${appId}/marketplace/${library.id}`}
                    className="library-link"
                  >
                    {library.displayName}
                    <Icon small type="new-window" />
                  </Link>
                  <p>
                    Made with{' '}
                    <span role="img" aria-label="heart">
                      ❤️
                    </span>{' '}
                    by {library.author}
                  </p>
                  {library.supportURL ? (
                    <Button
                      block
                      to={library.supportURL}
                      target="_blank"
                      teal
                      outlined
                    >
                      Get Support
                    </Button>
                  ) : null}
                </CalloutCard>
              )}
          </React.Fragment>,
        ]}
      </div>
    )
  }
}

const mapStateToProps = (state, { match, object }) => {
  const { libraryName, componentName } = object

  const app = getApp(state, match.params.appId)

  const mobileOnly = app?.webSettings?.layoutMode === 'mobile'

  return {
    libraryGlobals: getLibraryGlobals(state, libraryName, componentName),
    libraryVersion: getAppLibraryVersion(
      state,
      match.params.appId,
      object.libraryName
    ),
    appLibraries: getAppLibraries(state, match.params.appId),
    appId: match.params.appId,
    mobileOnly,
    magicLayout: getMagicLayout(state),
  }
}

export default withRouter(
  connect(mapStateToProps, { updateObject, setLibraryGlobals, resizeObject })(
    LibraryInspect
  )
)
