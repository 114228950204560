import { dataTypes, comparators, richObjectDataTypes } from '@adalo/constants'
import { buildIndex } from '@adalo/utils'

import { MenuOption, Submenu } from 'ducks/recommender'

import { getIcon } from 'utils/icons'

import { singularize, capitalize } from './strings'

const TYPE_LABELS = {
  [dataTypes.TEXT]: 'Text',
  [dataTypes.NUMBER]: 'Number',
  [dataTypes.BOOLEAN]: 'True / False',
  [dataTypes.DATE]: 'Date & Time',
  [dataTypes.DATE_ONLY]: 'Date',
  [dataTypes.IMAGE]: 'Image',
  [dataTypes.FILE]: 'File',
  [dataTypes.LOCATION]: 'Location',
}

const TYPE_OPTIONS = Object.entries(TYPE_LABELS).map(([key, label]) => ({
  label,
  value: key,
  icon: getIcon(key),
  dataset: {
    'adalo-id': `${key}-property`,
  },
}))

const CONTAINS_COMPARATORS = [
  comparators.LIST_CONTAINS,
  comparators.LIST_NOT_CONTAINS,
]

export const getTableOptions = opts => {
  const { datasources, typeValue, skipTables, singular, labelSuffix } = opts

  const skipSet = new Set(skipTables)

  const results = []

  for (const datasource of datasources) {
    const datasourceId = datasource.id

    const useCollections = !['apto-backend', undefined].includes(
      datasource.type
    )

    let collections = useCollections
      ? datasource.collections
      : datasource.tables

    collections = Object.keys(collections).map(id => ({
      ...collections[id],
      id,
    }))

    collections = collections
      .filter(t => !skipSet.has(t.id))
      .filter(t => useCollections || t.type !== 'api')

    for (const t of collections) {
      const name = t.name || ''
      let label = capitalize(name)

      if (singular) {
        label = singularize(label)
      }

      if (labelSuffix) {
        label = `${label} ${labelSuffix}`
      }

      const idKey = useCollections ? 'collectionId' : 'tableId'
      const value = { [idKey]: t.id, datasourceId }
      if (typeValue) value.type = typeValue

      results.push(new MenuOption(label, value, 'relationship-single'))
    }
  }

  return results
}

export const getTypeOptions = (datasources, opts = {}) => {
  let {
    excludeTypes,
    excludeTables,
    singleType,
    multiType,
    skipSingle,
    skipMulti,
    useCollections,
    singleLabel,
    multiLabel,
    singleSuffix,
    multiSuffix,
  } = opts

  const skipSet = new Set(excludeTypes)

  singleType = singleType || dataTypes.OBJECT
  multiType = multiType || dataTypes.LIST

  singleLabel = singleLabel || 'Reference'
  multiLabel = multiLabel || 'Collection'

  const baseTypes = TYPE_OPTIONS.filter(opt => !skipSet.has(opt.value))
  let singleTypes
  let multiTypes

  if (!skipSingle) {
    singleTypes = getTableOptions({
      useCollections,
      datasources,
      typeValue: singleType,
      skipTables: excludeTables,
      singular: true,
      labelSuffix: singleSuffix,
    })
  }

  if (!skipMulti) {
    multiTypes = getTableOptions({
      useCollections,
      datasources,
      typeValue: multiType,
      skipTables: excludeTables,
      labelSuffix: multiSuffix,
    })
  }

  const referenceOptions = [null]

  if (!skipSingle) {
    referenceOptions.push({ label: singleLabel, children: singleTypes })
  }

  if (!skipMulti) {
    referenceOptions.push({ label: multiLabel, children: multiTypes })
  }

  let result = baseTypes

  if (referenceOptions.length > 1) {
    result = result.concat(referenceOptions)
  }

  return result
}

export const getSimplePropertyOptions = ({ api }) => {
  const excludeTypes = []

  if (api) {
    excludeTypes.push(...richObjectDataTypes)
  }

  const skipSet = new Set(excludeTypes)

  return TYPE_OPTIONS.filter(opt => !skipSet.has(opt.value))
}

export const getReferencePropertyOptions = datasources => {
  const relationships = getTableOptions({ datasources })

  return relationships ? [new Submenu('Relationship', relationships)] : []
}

export const getAPIFieldTypes = datasource => {
  const datasources = datasource ? [datasource] : []

  const options = getTypeOptions(datasources, {
    excludeTypes: richObjectDataTypes,
    singleType: dataTypes.FOREIGN_KEY,
    useCollections: true,
    skipMulti: true,
    singleLabel: 'Foreign Key',
    singleSuffix: 'ID',
  }).concat([
    null,
    { label: 'Object', value: dataTypes.OBJECT },
    { label: 'Array', value: dataTypes.LIST },
  ])

  return options
}

export const getBasicAPIFieldTypes = () =>
  getTypeOptions(null, { skipSingle: true, skipMulti: true })

export const getDataType = (filter, table) => {
  const { fieldId } = filter

  let dataType

  if (['created_at', 'updated_at'].includes(fieldId)) {
    dataType = dataTypes.DATE
  } else if (fieldId === 'id') {
    dataType = dataTypes.NUMBER
  } else if (typeof fieldId === 'string') {
    const field = table.fields[fieldId]
    dataType = field?.type
  } else if (fieldId) {
    dataType = fieldId.source?.dataType
  }

  return dataType || dataTypes.TEXT
}

export const getComparisonDataType = (filter, table) => {
  const { fieldId, comparator } = filter
  const { source } = fieldId || {}
  const { tableId } = source || {}
  let dataType = getDataType(filter, table)

  if (CONTAINS_COMPARATORS.includes(comparator) && tableId) {
    dataType = { tableId, type: 'belongsTo' }
  }

  return dataType
}

export const getOptions = (tables, datasourceId) => {
  const result = getSimplePropertyOptions({ api: false })

  const datasources = [
    { id: datasourceId, tables: buildIndex(tables, t => t.id) },
  ]

  result.push(null, ...getReferencePropertyOptions(datasources))

  return result
}
