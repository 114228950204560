/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useSelector } from 'react-redux'

import { getApp } from 'ducks/apps'
import { FeatureTemplate } from 'ducks/featureTemplates'

type DetailsBannerProps = {
  feature: FeatureTemplate
  appId: string
}

const DetailsBanner: React.FC<DetailsBannerProps> = ({
  feature,
  appId,
}): JSX.Element => {
  const { demo, demoMobile, name } = feature

  const app = useSelector(state => getApp(state, appId))

  const mobileOnly = app.webSettings?.layoutMode === 'mobile'

  const platformDemo = mobileOnly && demoMobile ? demoMobile : demo

  return (
    <div className="feature-item__details-banner">
      <iframe
        src={`https://demo.arcade.software/${platformDemo}?embed`}
        loading="lazy"
        // @ts-ignore
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        title={`${name} - Demo`}
      />
    </div>
  )
}

export default DetailsBanner
