import { dataTypes } from '@adalo/constants'

export const getFields = (table, state) => {
  if (!table) {
    return {}
  }

  const fields = {
    ...table.fields,
    created_at: {
      type: dataTypes.DATE,
      name: 'Created Date',
    },
    updated_at: {
      type: dataTypes.DATE,
      name: 'Updated Date',
    },
  }

  fields.id = {
    type: dataTypes.NUMBER,
    name: 'ID',
  }

  return fields
}

export const getFieldIds = fields => {
  const primaryFields = []
  const others = []

  for (const fieldId in fields) {
    if (fields[fieldId].isPrimaryField) {
      primaryFields.push(fieldId)
    } else {
      others.push(fieldId)
    }
  }

  return primaryFields.concat(others)
}
