import { useEffect, useMemo, useRef, useState } from 'react'
import { DefaultRootState, connect, useDispatch } from 'react-redux'
import { sortBy } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import Fuse from 'fuse.js'

import {
  AppExternalUsers,
  Datasource,
} from 'components/Editor/XanoExternalDatabaseModal/lib/user'
import { IconButton } from 'components/Shared/Icon'

import { App, getApp } from 'ducks/apps'
import { getCurrentUser, UserState } from 'ducks/users'
import { setTool } from 'ducks/editor/tools'
import {
  getMarketplaceComponents,
  getPrivateLibraries,
  selectComponents,
} from 'ducks/marketplace'
import { getDefaultDatasource } from 'ducks/apps/datasources'
import {
  ensureScreenTemplatesAreLoaded,
  getScreenTemplatesState,
} from 'ducks/editor/screenTemplates'
import { FeatureTemplate } from 'ducks/featureTemplates'
import { getLicenses } from 'ducks/marketplace/licenses'

import { assetURL } from 'utils/assets'
import { FormattedSection } from 'utils/layoutSections'
import { useThrottledValue } from 'utils/debounce'
import { verifyXanoApp } from 'utils/externalDatabases'

import { getMarketplaceLibraries, handleChildren } from '../ComponentsTab'
import Tab from '../Tab'

import {
  getFormattedSections,
  isFormattedSection,
  renderSectionOption,
} from './sectionHelpers'
import {
  FormattedFeatureTemplate,
  getFormattedFeatures,
  isFormattedFeatureTemplate,
  renderFeatureOption,
} from './featureHelpers'
import { EmptyState } from './EmptyState'
import {
  Component,
  Library,
  PrivateLibrary,
  getInternalComponents,
  renderComponent,
} from './componentHelpers'

interface SearchProps {
  appId: string
  options: Category[]
  app: App
  datasourceId: string | undefined
  isAdmin: boolean
}

interface Category {
  label: string
  children: (
    | Component
    | Library
    | FormattedSection
    | FormattedFeatureTemplate
  )[]
  emptyCallout?: JSX.Element
  fuseKeys: string[]
  maxItems?: number
}

const COMPONENTS_LABEL = 'Components'
const MARKETPLACE_COMPONENTS_LABEL = 'Marketplace Components'
const SECTIONS_LABEL = 'Sections'
const FEATURE_TEMPLATES_LABEL = 'Feature Templates'
const PRIVATE_COMPONENTS_LABEL = 'Private Components'
const SCREENS_LABEL = 'Screens'

type Label =
  | typeof COMPONENTS_LABEL
  | typeof MARKETPLACE_COMPONENTS_LABEL
  | typeof SECTIONS_LABEL
  | typeof FEATURE_TEMPLATES_LABEL
  | typeof PRIVATE_COMPONENTS_LABEL

const OPEN_CATEGORIES_WHEN_NO_RESULTS = [
  COMPONENTS_LABEL,
  MARKETPLACE_COMPONENTS_LABEL,
  SECTIONS_LABEL,
  FEATURE_TEMPLATES_LABEL,
]

const categories: Category[] = [
  {
    label: COMPONENTS_LABEL,
    children: [],
    emptyCallout: <EmptyState icon="components-empty" />,
    fuseKeys: ['label', 'searchTerms'],
  },
  {
    label: MARKETPLACE_COMPONENTS_LABEL,
    children: [],
    emptyCallout: <EmptyState icon="marketplace-empty" />,
    fuseKeys: ['label', 'library', 'displayName'],
  },
  {
    label: SECTIONS_LABEL,
    children: [],
    emptyCallout: <EmptyState label="Sections" icon="sections-empty" />,
    fuseKeys: ['primaryTags', 'secondaryTags'],
  },
  {
    label: FEATURE_TEMPLATES_LABEL,
    children: [],
    emptyCallout: <EmptyState label="Features" icon="features-empty" />,
    fuseKeys: ['label', 'value.summary', 'searchTerms'],
  },
  {
    label: PRIVATE_COMPONENTS_LABEL,
    children: [],
    emptyCallout: <EmptyState label="Private" icon="components-empty" />,
    fuseKeys: ['label', 'name', 'displayName'],
  },
]

function filter(fullCategories: Category[], searchString: string): Category[] {
  const lowercaseSearchString = searchString.toLowerCase()

  return fullCategories.map(option => {
    const { children, fuseKeys } = option

    const fuse = new Fuse(children, {
      includeScore: true,
      threshold: 0.3,
      keys: fuseKeys,
    })

    let fuseChildResults = fuse
      .search(lowercaseSearchString)
      .map(({ item }) => item)

    if (option.label === MARKETPLACE_COMPONENTS_LABEL) {
      fuseChildResults = (
        fuseChildResults as unknown as (Component | Library)[]
      ).sort((a, b) => (a.installed && !b.installed ? -1 : 1))
    }

    return { ...option, children: fuseChildResults }
  })
}

const validTabs = ['component', 'section', 'feature']

const accordionOrderPerLastTab: { [k: string]: string[] } = {
  component: [
    COMPONENTS_LABEL,
    MARKETPLACE_COMPONENTS_LABEL,
    SECTIONS_LABEL,
    FEATURE_TEMPLATES_LABEL,
    PRIVATE_COMPONENTS_LABEL,
    SCREENS_LABEL,
  ],
  section: [
    SECTIONS_LABEL,
    FEATURE_TEMPLATES_LABEL,
    COMPONENTS_LABEL,
    MARKETPLACE_COMPONENTS_LABEL,
    PRIVATE_COMPONENTS_LABEL,
    SCREENS_LABEL,
  ],
  feature: [
    FEATURE_TEMPLATES_LABEL,
    SECTIONS_LABEL,
    COMPONENTS_LABEL,
    MARKETPLACE_COMPONENTS_LABEL,
    PRIVATE_COMPONENTS_LABEL,
    SCREENS_LABEL,
  ],
}

const pageSizes = {
  [COMPONENTS_LABEL]: 8,
  [MARKETPLACE_COMPONENTS_LABEL]: 8,
  [SECTIONS_LABEL]: 6,
  [FEATURE_TEMPLATES_LABEL]: 4,
  [PRIVATE_COMPONENTS_LABEL]: 8,
}

const useLastTab = (appId: string) => {
  const [lastTab, setLastTab] = useState('component')
  const location = useLocation()

  useEffect(() => {
    if (`lastTab-${appId}` in localStorage) {
      const localStorageLastTab = localStorage.getItem(`lastTab-${appId}`)

      if (localStorageLastTab && validTabs.includes(localStorageLastTab)) {
        setLastTab(localStorageLastTab)
      }
    }
  }, [appId, location])

  return lastTab
}

export const getSearchParams = (search: string): string => {
  return new URLSearchParams(search).get('q') || ''
}

const GlobalSearch = (props: SearchProps): JSX.Element => {
  const { appId, options: unsortedOptions, app, datasourceId, isAdmin } = props
  const location = useLocation()
  const [search, setSearch] = useState(getSearchParams(location.search))
  const searchInput = useRef<HTMLInputElement>(null)
  const [maxItems, setMaxItems] = useState({ ...pageSizes })

  const [results, setResults] = useState([] as Category[])
  const history = useHistory()
  const dispatch = useDispatch()

  const lastTab = useLastTab(appId)
  const options = useMemo(() => {
    const sortedOptions = sortBy(unsortedOptions, option =>
      accordionOrderPerLastTab[lastTab]?.indexOf(option.label)
    )

    return sortedOptions
  }, [lastTab, appId, unsortedOptions])

  useEffect(() => {
    dispatch(ensureScreenTemplatesAreLoaded('responsive'))
  }, [])

  useEffect(() => {
    if (!search) {
      setSearch(getSearchParams(location.search))
    } else {
      searchInput.current?.focus()
    }
  }, [search, location.search])

  useEffect(() => {
    if (app?.OrganizationId) {
      dispatch(getMarketplaceComponents(app.OrganizationId))
    }
  }, [app?.OrganizationId])

  useEffect(() => {
    if (search) {
      const searchResults = filter(options, search).map(
        ({ label, ...category }) => {
          const max = maxItems[label as Label]

          return {
            ...category,
            label,
            maxItems: max,
          }
        }
      )
      setResults(searchResults)
    }
  }, [options, search, maxItems])

  const clearSearch = (): void => {
    history.push(`/apps/${appId}/screens/add`)
    setSearch('')
    setMaxItems({ ...pageSizes })
  }

  const showMore = (categoryName: Label): void => {
    const newMaxItems = maxItems
    newMaxItems[categoryName] = maxItems[categoryName] + pageSizes[categoryName]
    const newResults = results.map(({ label, ...category }) => {
      const max = maxItems[label as Label]

      return {
        ...category,
        label,
        maxItems: max,
      }
    })

    setMaxItems(newMaxItems)
    setResults(newResults)
  }

  const handleSearch = (
    event: React.ChangeEvent<HTMLInputElement> | string
  ) => {
    const value = typeof event === 'string' ? event : event.target.value

    if (value) {
      if (!search) {
        history.push(`/apps/${appId}/screens/add/search?q=${value}`)
      } else {
        history.replace(`/apps/${appId}/screens/add/search?q=${value}`)
      }
    } else if (search) {
      clearSearch()
    }

    setSearch(value)
    setMaxItems({ ...pageSizes })
  }

  const handleSelect = (
    type: Record<string, unknown> | string | undefined,
    startPosition: [number, number]
  ): void => {
    if (!type) {
      return
    }

    let toolOptions
    let toolType

    if (typeof type === 'object') {
      toolOptions = type['options']
      toolType = type['type']
    } else {
      toolOptions = {}
      toolType = type
    }

    history.push(`/apps/${appId}/screens`)
    dispatch(setTool(toolType, toolOptions, startPosition))
  }

  const onViewClick = (libraryId: string) => {
    history.push(`/apps/${appId}/marketplace/${libraryId}`)
  }

  const renderItem = (
    item: Component | Library | FormattedSection
  ): JSX.Element => {
    if (isFormattedSection(item)) {
      return renderSectionOption({
        handleSelect,
        handleSelectChip: (tag: string) => {
          handleSearch(tag)
        },
        isAdmin,
        ...item,
      })
    } else if (isFormattedFeatureTemplate(item)) {
      return renderFeatureOption({
        appId,
        datasourceId,
        dispatch,
        history,
        ...item,
      })
    }

    return renderComponent(item, handleSelect, onViewClick)
  }

  const debouncedResult = useThrottledValue(results, 200)

  const categoriesWithResults = useMemo(() => {
    const cats = debouncedResult
      .filter(r => r.children.length > 0)
      .map(r => r.label)

    return cats.length > 0 ? cats : OPEN_CATEGORIES_WHEN_NO_RESULTS
  }, [debouncedResult])

  return (
    <div className="global-search-panel">
      <div className="editor-add-panel-search">
        <i className="material-icons">search</i>
        <input
          placeholder="Search..."
          value={search}
          onChange={handleSearch}
          autoFocus
          ref={searchInput}
        />
        {search && (
          <IconButton type="close-big" white rounded onClick={clearSearch} />
        )}
      </div>
      {search && (
        <div className="global-search-results">
          <Tab
            key="searchResults"
            emptyMessage="No components found"
            options={results}
            platform="responsive"
            renderItem={renderItem}
            defaultOpen={categoriesWithResults}
            className="editor-global-search"
            showMore={showMore}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (
  state: DefaultRootState,
  { appId }: { appId: string }
) => {
  const app = getApp(state, appId)
  const user = getCurrentUser(state as UserState)
  const isAdmin = user?.admin ?? false
  const datasource = getDefaultDatasource(state, appId) as
    | Datasource
    | undefined
  const isXanoApp = verifyXanoApp(app as App & AppExternalUsers)
  const licenses = getLicenses(state, app?.OrganizationId, appId)

  const options = [...categories].map(category => {
    if (category.label === COMPONENTS_LABEL) {
      return {
        ...category,
        children: handleChildren(
          app,
          getInternalComponents({ isResponsiveApp: !!app?.magicLayout })
        ) as Component[],
      }
    } else if (category.label === MARKETPLACE_COMPONENTS_LABEL) {
      const marketplaceComponents = selectComponents(state) as {
        components: Library[]
      }

      const withLibraryOpts = true

      const marketplaceLibraries = getMarketplaceLibraries(
        marketplaceComponents,
        licenses,
        withLibraryOpts
      )

      return {
        ...category,
        children: handleChildren(app, marketplaceLibraries) as Component[],
      }
    } else if (category.label === SECTIONS_LABEL) {
      const formattedSections = getFormattedSections(state, app)

      return { ...category, children: formattedSections }
    } else if (category.label === FEATURE_TEMPLATES_LABEL) {
      const formattedTemplates = isXanoApp ? [] : getFormattedFeatures(state)

      const { list: screensList } = getScreenTemplatesState(
        state,
        'responsive'
      ) as {
        list: Partial<FeatureTemplate & { thumbnail: string }>[]
      }

      const screens = screensList.map(screenTemplate => {
        const { name, thumbnail } = screenTemplate

        const child = {
          ...screenTemplate,
          image: assetURL(thumbnail),
          summary: '',
        }

        return {
          label: name || 'Screen',
          value: child as FeatureTemplate,
          searchTerms: 'Empty screen',
        }
      })

      return {
        ...category,
        children: formattedTemplates.concat(screens),
        label: isXanoApp ? SCREENS_LABEL : FEATURE_TEMPLATES_LABEL,
      }
    } else if (category.label === PRIVATE_COMPONENTS_LABEL) {
      const privateLibraries = getPrivateLibraries(state) as PrivateLibrary[]

      const privateMarketplaceLibraries =
        getMarketplaceLibraries(privateLibraries)

      return {
        ...category,
        children: handleChildren(
          app,
          privateMarketplaceLibraries
        ) as Component[],
      }
    } else {
      return { ...category, children: [] }
    }
  })

  return { options, app, appId, datasourceId: datasource?.id, isAdmin }
}

export default connect(mapStateToProps, { setTool })(GlobalSearch)
