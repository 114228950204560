import { GROUP, DeviceType, LAYOUT_SECTION, SECTION } from '@adalo/constants'
import { DEFAULT_DEVICE_SETTINGS } from 'utils/defaultDeviceSettings'
import getDeviceObject from 'utils/getDeviceObject'
import { removeUnusedSize } from 'utils/objects/removeUnusedSize'
import { DeviceValue } from 'utils/responsiveTypes'
import translateChildren from 'utils/operations/translateChildren'

import getObject from '../objects/helpers/getObject'
import { getParent } from '../device-layouts/utils'

import InstructionState from '../types/InstructionState'
import updateChangedObject from './updateChangedObject'
import {
  LayoutSectionPurpose,
  getContainerFromSection,
} from '../../../utils/layoutSections'
import usesSharedLayout from '../../../utils/objects/usesSharedLayout'

export interface EnableDeviceSpecificLayoutOptions {
  objectId: string
  device: DeviceValue
}

export interface EnableDeviceSpecificLayoutInstruction {
  operation: 'enableDeviceSpecificLayout'
  options: EnableDeviceSpecificLayoutOptions
}

const devices = [DeviceType.MOBILE, DeviceType.TABLET, DeviceType.DESKTOP]

export const enableDeviceSpecificLayoutHandler = (
  state: InstructionState,
  { objectId, device }: EnableDeviceSpecificLayoutOptions
): InstructionState => {
  const { list, pathMap } = state

  const { shared } = getObject(list, pathMap, objectId)

  const [first, second] = devices.filter(item => item !== device)
  const devicesToUpdate = new Set([device])

  if (shared && first && second && shared[first] !== shared[second]) {
    if (shared[first]) {
      devicesToUpdate.add(first)
    } else {
      devicesToUpdate.add(second)
    }
  }

  let currentList = list

  for (const currentDevice of devicesToUpdate) {
    const object = getObject(currentList, pathMap, objectId)

    // TODO (Tom) make getDeviceObject return these values? or a separate function?
    const {
      x,
      y,
      responsivity,
      positioning,
      width,
      minWidth,
      minWidthEnabled,
      maxWidth,
      maxWidthEnabled,
      height,
      left,
      right,
    } = getDeviceObject(object, currentDevice)

    const layoutAttributes = removeUnusedSize(object, {
      x,
      y,
      width,
      height,
      ...(responsivity !== undefined && {
        responsivity,
      }),
      ...(positioning !== undefined && {
        positioning,
      }),
      ...(left !== undefined &&
        right !== undefined && {
          left,
          right,
        }),
      ...(minWidth !== undefined && { minWidth }),
      ...(maxWidth !== undefined && { maxWidth }),
      ...(minWidthEnabled !== undefined && { minWidthEnabled }),
      ...(maxWidthEnabled !== undefined && { maxWidthEnabled }),
    })

    let newList = updateChangedObject(
      currentList,
      pathMap,
      translateChildren(
        // updated object
        {
          ...object,
          shared: {
            ...DEFAULT_DEVICE_SETTINGS,
            ...object.shared,
            // set shared settings to false for given device
            [currentDevice]: false,
          },
          [currentDevice]: layoutAttributes,
        },
        // current object
        object,
        currentDevice,
        currentDevice === undefined
      ),
      currentDevice
    )

    // When device-specific layout is enabled for any child of a group,
    // automatically enable device-specific layout on the parent group.
    // (This may be recursive up the hierarchy if the group is itself a child of another group.)
    const parentObject = getParent(newList, pathMap, objectId)
    if (
      parentObject &&
      usesSharedLayout(parentObject, currentDevice) &&
      ([GROUP, LAYOUT_SECTION].includes(parentObject.type) ||
        (parentObject.type === SECTION &&
          parentObject.purpose === LayoutSectionPurpose.LAYOUT_HELPER))
    ) {
      const parentInstructionState = {
        ...state,
        list: newList,
      }
      const parentInstructionOptions = {
        objectId: parentObject.id,
        device: currentDevice,
      }
      ;({ list: newList } = enableDeviceSpecificLayoutHandler(
        parentInstructionState,
        parentInstructionOptions
      ))
    }

    if (object.type === LAYOUT_SECTION) {
      const container = getContainerFromSection(object)
      if (usesSharedLayout(container, currentDevice)) {
        const childInstructionState = {
          ...state,
          list: newList,
        }
        const childInstructionOptions = {
          objectId: container.id,
          device: currentDevice,
        }
        ;({ list: newList } = enableDeviceSpecificLayoutHandler(
          childInstructionState,
          childInstructionOptions
        ))
      }
    }

    currentList = newList
  }

  return {
    ...state,
    list: currentList,
  }
}

const enableDeviceSpecificLayout = (
  objectId: string,
  device: DeviceValue
): EnableDeviceSpecificLayoutInstruction => ({
  operation: 'enableDeviceSpecificLayout',
  options: {
    objectId,
    device,
  },
})

export default enableDeviceSpecificLayout
