import { useDispatch, useSelector } from 'react-redux'
import { LIST } from '@adalo/constants'
import { getDeviceType } from '@adalo/utils'
import { getParentScreenComponent, runInstructions } from 'ducks/editor/objects'
import {
  resetSharedLayout as resetToSharedInstruction,
  disableDeviceSpecificCustomListColumns as disableDeviceSpecificCustomListColumnsInstruction,
} from 'ducks/editor/instructions'
import { Instruction } from 'ducks/editor/instructions/applyInstructions'
import { openAccordion } from 'ducks/accordions'
import { EditorObject } from 'utils/responsiveTypes'
import { hasSomeSharedLayout } from 'utils/objects/hasLayout'
import {
  getLayoutSettingsGroup,
  getLayoutSettingsItemId,
} from 'utils/accordionGroups'
import { getApp } from 'ducks/apps'

const useResetToShared = (object: EditorObject) => {
  const { id: objectId, type } = object

  const dispatch = useDispatch()
  const screen = useSelector(
    state => getParentScreenComponent(state, objectId) as EditorObject
  )

  const resetToShared = () => {
    const deviceToResetTo = getDeviceType(screen?.width)

    const instructions: Instruction[] = []

    if (type === LIST) {
      instructions.push(
        disableDeviceSpecificCustomListColumnsInstruction(objectId)
      )
    }

    instructions.push(resetToSharedInstruction(objectId, deviceToResetTo))

    dispatch(runInstructions(instructions))

    // Open the shared layout accordion for this object
    const groupId = getLayoutSettingsGroup(objectId)
    const accordionItemId = getLayoutSettingsItemId(objectId, 'shared')
    dispatch(openAccordion(groupId, accordionItemId))
  }

  return resetToShared
}

type Props = {
  object: EditorObject
  appId: string
}

const ResetToShared: React.FC<Props> = props => {
  const { object, appId } = props
  const resetToShared = useResetToShared(object)
  const app = useSelector(state => getApp(state, appId))
  // TODO: move this check into a reusable function
  const mobileOnly = app?.webSettings?.layoutMode === 'mobile'

  // Only show ResetToShared if all layouts are custom
  const showResetToShared = !hasSomeSharedLayout(object) && !mobileOnly

  if (!showResetToShared) {
    return null
  }

  let showLayoutControls = true
  if (object.deviceVisibility) {
    // We only want to show layout controls if the object is visible on some device
    showLayoutControls = Object.values(object.deviceVisibility).some(dv => dv)
  }

  if (!showLayoutControls) {
    return null
  }

  const onClick = () => resetToShared()

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    <div className="reset-to-shared" onClick={onClick}>
      <p>RESET ALL LAYOUTS TO SHARED</p>
    </div>
  )
}

export default ResetToShared
