import React, { useEffect, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import {
  responsivePositioningOptions,
  resizingOptions,
  LAYOUT_SECTION,
} from '@adalo/constants'
import { getDeviceType } from '@adalo/utils'
import Icon from 'components/Shared/Icon'
import classNames from 'classnames'

import { runInstructions, getParentScreenComponent } from 'ducks/editor/objects'
import { toggleFixedPosition } from 'ducks/editor/instructions'
import { openAccordion } from 'ducks/accordions'
import { getApp } from 'ducks/apps'
import { resetSelection } from 'ducks/editor/selection'
import useSetScreenDevice from 'hooks/editor/useSetScreenDevice'
import { CONTAINER_TYPES } from 'utils/positioning'
import {
  hasSomeDeviceSpecificLayout,
  hasSomeSharedLayout,
} from 'utils/objects/hasLayout'
import { DEFAULT_DEVICE_SETTINGS } from 'utils/defaultDeviceSettings'
import getDeviceObject from 'utils/getDeviceObject'
import {
  getLayoutSettingsGroup,
  getLayoutSettingsItemId,
} from 'utils/accordionGroups'
import { GroupedAccordion } from 'components/Shared/Accordion'
import { isSectionElement } from 'utils/layoutSections'
import VisibilitySection from '../VisibilitySection'
import FixedControl from '../FixedControl'
import LayoutSettingsHeader from './LayoutSettingsHeader/Header'
import CustomLayoutSettings from './CustomLayout/CustomLayoutSettings'
import LayoutControls from './LayoutControls'
import { LayoutAdvancedSettings } from './LayoutAdvancedSettings'
import './ResponsiveLayout.scss'

const { SCALES_WITH_PARENT } = resizingOptions
const { FIXED_ON_SCROLL } = responsivePositioningOptions

const ResponsiveLayoutWrapper = ({
  object,
  visibleDeviceTypes,
  visibleDeviceTypesKey,
  updateObject,
  componentParent,
  runInstructions,
  parentScreenDevice,
  layoutSettingsGroupId,
  openAccordion,
  history,
  resetSelection,
}) => {
  const { responsivity } = object

  const onIsFixedChange = isFixed => {
    runInstructions([toggleFixedPosition(object.id, isFixed)])
  }

  const hasComponentParent = CONTAINER_TYPES.includes(componentParent?.type)
  const isFixed = responsivity?.verticalPositioning === FIXED_ON_SCROLL

  let showLayoutControls = true
  if (object.deviceVisibility) {
    // We only want to show layout controls if the object is visible on some device
    showLayoutControls = Object.values(object.deviceVisibility).some(dv => dv)
  }

  const { appId } = useParams()
  const app = useSelector(state => getApp(state, appId))

  const mobileOnly = app.webSettings?.layoutMode === 'mobile'

  const handleClick = () => {
    resetSelection()

    history.push(`/apps/${app.id}/app-settings?active=layout`)
  }

  useEffect(() => {
    const currentScreenIsShared = visibleDeviceTypes.find(
      vd => vd.device === parentScreenDevice
    )
    // This is to handle the scenario where moving/resizing an object on a device that's not it's initial device
    // triggers a custom layout for that device. In that case we want to open the layout settings for that device.
    const accordionItemId = getLayoutSettingsItemId(
      object.id,
      currentScreenIsShared?.shared ? 'shared' : parentScreenDevice
    )
    openAccordion(layoutSettingsGroupId, accordionItemId)
  }, [visibleDeviceTypesKey, parentScreenDevice])

  const mobileOnlyLayoutSettings = (
    <>
      <div
        className={classNames({
          'shared-layout-settings': true,
          'mobile-only-shared-layout-settings': mobileOnly,
        })}
      >
        <LayoutControls
          object={object}
          updateObject={updateObject}
          componentParent={componentParent}
          mobileOnly={mobileOnly}
        />
      </div>
    </>
  )

  const appLayoutText =
    app.webSettings?.layoutMode === 'mobile' ? 'Mobile-Only' : 'Responsive'

  return (
    <div className="responsive-settings">
      {!mobileOnly && (
        <VisibilitySection object={object} updateObject={updateObject} />
      )}
      {mobileOnly && mobileOnlyLayoutSettings}
      <FixedControl
        object={object}
        isFixed={isFixed}
        onChange={onIsFixedChange}
        hasComponentParent={hasComponentParent}
      />
      {!isSectionElement(object) && <div className="magic-layout-separator" />}
      {showLayoutControls && !mobileOnly && (
        <>
          <AutoCustomLayoutSharedLayoutSettings
            visibleIcons={visibleDeviceTypes}
            object={object}
            updateObject={updateObject}
            componentParent={componentParent}
          />
          <div className="responsive-layout-settings">
            <CustomLayoutSettings
              visibleDeviceTypes={visibleDeviceTypes}
              object={object}
              updateObject={updateObject}
              componentParent={componentParent}
            />
          </div>
        </>
      )}
      {mobileOnly && (
        <div className="mobile-layout-help-text-container">
          <div className="mobile-layout-help-header">
            <Icon type="magic-layout-enabled" />
            <h2>App Layout Type: {appLayoutText} </h2>
          </div>
          <p>
            If you want to design your app to work across mobile, tablet, and
            desktop devices, you can change those global layout options in
            &nbsp;
            <a onClick={handleClick} className="help-doc">
              Settings
            </a>
            .
          </p>
        </div>
      )}
    </div>
  )
}

export const BaseSharedLayoutSettings = ({
  visibleIcons,
  object,
  updateObject,
  componentParent,
}) => {
  return (
    <div className="responsive-layout-settings">
      <div className="shared-responsive-layout">
        <LayoutSettingsHeader
          visibleIcons={visibleIcons}
          shared
          object={object}
          updateObject={updateObject}
        />
        <div className="shared-layout-settings">
          <LayoutControls
            object={object}
            updateObject={updateObject}
            componentParent={componentParent}
          />
        </div>
      </div>
    </div>
  )
}

const getNewScreenDevice = (object, currentScreenDevice) => {
  const { initialDevice, shared } = object

  let newScreenDevice = currentScreenDevice

  if (!initialDevice) {
    // Feature not available outside of hasAutoCustomLayout
    return undefined
  }

  if (shared?.tablet && shared?.desktop) {
    newScreenDevice = 'tablet'
  } else if (shared?.mobile && shared?.desktop) {
    newScreenDevice = initialDevice === 'tablet' ? 'mobile' : initialDevice
  } else if (shared?.tablet && shared?.mobile) {
    newScreenDevice = 'tablet'
  } else if (shared?.desktop && !shared?.tablet && !shared?.mobile) {
    newScreenDevice = 'desktop'
  } else if (shared?.tablet && !shared?.desktop && !shared?.mobile) {
    newScreenDevice = 'tablet'
  } else if (shared?.mobile && !shared?.tablet && !shared?.desktop) {
    newScreenDevice = 'mobile'
  }

  return newScreenDevice
}

const AutoCustomLayoutSharedLayoutSettings = ({
  visibleIcons,
  object,
  updateObject,
  componentParent,
}) => {
  const setScreenDevice = useSetScreenDevice(object.id)
  const currentScreenDevice = getDeviceType(componentParent.width)

  const showAdvanced = useMemo(() => {
    const deviceObject = getDeviceObject(object, currentScreenDevice)
    const isScalable =
      deviceObject.responsivity.horizontalScaling === SCALES_WITH_PARENT

    return (
      object.type === LAYOUT_SECTION ||
      isSectionElement(object) ||
      isScalable === true
    )
  }, [object, currentScreenDevice])

  const showingResetToShared = !hasSomeSharedLayout(object)
  // Don't show Shared Layout if nothing is shared
  if (showingResetToShared) {
    return null
  }

  const keepExpanded = !hasSomeDeviceSpecificLayout(object)

  // Needs to match groupId defined in CustomLayoutSettings
  const groupId = getLayoutSettingsGroup(object.id)
  const accordionItemId = getLayoutSettingsItemId(object.id, 'shared')

  const renderHeader = expanded => (
    <LayoutSettingsHeader
      visibleIcons={visibleIcons}
      shared
      object={object}
      updateObject={updateObject}
      expanded={expanded}
    />
  )

  const renderLayoutControls = () => (
    <div className="shared-layout-settings-with-auto-layout">
      <LayoutControls
        object={object}
        updateObject={updateObject}
        componentParent={componentParent}
      />
    </div>
  )

  const onToggled = toggled => {
    if (toggled) {
      const hasDeviceSpecificLayout = hasSomeDeviceSpecificLayout(object)
      if (hasDeviceSpecificLayout) {
        const newParentDevice = getNewScreenDevice(object, currentScreenDevice)
        if (newParentDevice && newParentDevice !== currentScreenDevice) {
          setScreenDevice(newParentDevice)
        }
      }
    }
  }

  const renderAdvanced = () => (
    <LayoutAdvancedSettings object={object} device={currentScreenDevice} />
  )

  const layoutControlsAccordion = (
    <GroupedAccordion
      className={classNames({
        'layout-section-layout-controls-accordion': showAdvanced,
      })}
      group={groupId}
      itemId={accordionItemId}
      key={accordionItemId}
      closeDisabled
      renderHeader={renderHeader}
      renderChildren={renderLayoutControls}
      onToggled={onToggled}
      keepExpanded={keepExpanded}
      renderAdvanced={showAdvanced ? renderAdvanced : null}
      sectionAdvancedSettings={showAdvanced}
      hideDone={showAdvanced}
      {...(showAdvanced && { color: 'black' })}
    />
  )

  return (
    <div className="shared-responsive-layout-with-auto-layout">
      {layoutControlsAccordion}
    </div>
  )
}

const mapStateToProps = (state, { object }) => {
  const deviceVisibility = object?.deviceVisibility || DEFAULT_DEVICE_SETTINGS

  const parentScreen = getParentScreenComponent(state, object.id)
  const parentScreenDevice = getDeviceType(parentScreen.width)
  const layoutSettingsGroupId = getLayoutSettingsGroup(object.id)

  let visibleDeviceTypes = Object.keys(deviceVisibility).filter(
    deviceType => deviceVisibility[deviceType]
  )

  visibleDeviceTypes = visibleDeviceTypes
    .map(item => {
      const device = item
      const shared = object?.shared ? object?.shared[device] : true

      return {
        device,
        shared,
      }
    })
    .reverse()

  // Setup a string to use for comparison in a useEffect (better than comparing objects)
  const visibleDeviceTypesKey = visibleDeviceTypes.reduce(
    (acc, i) => `${acc}${i.device}(${i.shared})`,
    object.id
  )

  return {
    visibleDeviceTypes,
    visibleDeviceTypesKey,
    parentScreenDevice,
    layoutSettingsGroupId,
  }
}

export default withRouter(
  connect(mapStateToProps, { runInstructions, openAccordion, resetSelection })(
    ResponsiveLayoutWrapper
  )
)
