import Button from 'components/Shared/Button'

import './styles.scss'

type Props = {
  onClick: () => void
}

const CallToAction = ({ onClick }: Props): JSX.Element => {
  const icon = 'features-gallery'

  const title = 'View Features Gallery'

  return (
    <div className="feature-templates-cta__collapsed">
      <Button
        listAddButton
        icon={icon}
        onClick={onClick}
        data-adalo-id="feature-templates-launch-modal"
      >
        {title}
      </Button>
    </div>
  )
}

export default CallToAction
