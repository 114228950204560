import { useState } from 'react'
import { useSelector } from 'react-redux'
import { truncate } from 'lodash'
import classNames from 'classnames'
import { getCurrentUser } from 'ducks/users'
import { SessionData, useAppRoomSessions } from './hooks'

import './AppRooms.scss'

// Reference: https://graphemica.com/%E2%96%B2
const UP_ARROW = '\u25B2'
// Reference: https://graphemica.com/%E2%96%BC
const DOWN_ARROW = '\u25BC'

type GripProps = {
  caption: string
  expanded: boolean
  onClick: () => void
}

const Grip = (props: GripProps) => {
  const { expanded, onClick, caption } = props
  const icon = expanded ? DOWN_ARROW : UP_ARROW

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="grip" onClick={onClick}>
      {`${icon} ${caption}`}
    </div>
  )
}

const getGripCaption = (sessions: SessionData[] | undefined | null) => {
  let caption = 'No sessions'
  if (sessions && sessions.length > 0) {
    if (sessions.length === 1) {
      caption = `No other users viewing app`
    } else {
      const otherUsers = sessions.length - 1
      const suffix = otherUsers > 1 ? 's' : ''
      caption = `${otherUsers} other user${suffix} viewing app`
    }
  }

  return caption
}

type SessionRowProps = {
  session: SessionData
}

const SessionRow = (props: SessionRowProps) => {
  const { session } = props

  const defaultAccent = '#fff'
  const {
    name,
    isCurrentSession,
    userId,
    accentColor = defaultAccent,
  } = session

  const style = { color: accentColor }
  const avatarStyle = { backgroundColor: accentColor }

  const maxNameLength = 16 - String(userId).length
  const truncatedName = truncate(name, {
    length: maxNameLength,
    omission: '...',
  })

  const userIdLabel = <div className="user-id">#{userId}</div>

  let currentSessionIndicator = null
  if (isCurrentSession) {
    currentSessionIndicator = (
      <div className="current-session-indicator">● You</div>
    )
  }

  return (
    <div className="session-row" style={style}>
      <div className="avatar" style={avatarStyle} />
      <div className="name" data-after-content={userIdLabel}>
        {truncatedName}
        {userIdLabel} {currentSessionIndicator}
      </div>
    </div>
  )
}

const ROW_HEIGHT = 32
const PADDING_Y = 16

const AppRooms = () => {
  const [expanded, setExpanded] = useState(false)
  const { appRoomSessions, refetch } = useAppRoomSessions()

  const gripCaption = getGripCaption(appRoomSessions)

  const gripOnClick = () => {
    refetch()
    setExpanded(!expanded)
  }

  const containerClassName = classNames('app-rooms-container', { expanded })

  let containerHeight = 0
  if (expanded && appRoomSessions?.length) {
    containerHeight = appRoomSessions?.length * ROW_HEIGHT + PADDING_Y * 2
  }

  const containerStyle = { height: containerHeight }

  return (
    <div className={containerClassName} style={containerStyle}>
      <Grip caption={gripCaption} expanded={expanded} onClick={gripOnClick} />
      <div className="content">
        {appRoomSessions?.map(s => (
          <SessionRow session={s} key={s.sessionId} />
        ))}
      </div>
    </div>
  )
}

const AppRoomsWrapper: React.FC = () => {
  const currentUser = useSelector(getCurrentUser)

  if (!currentUser?.admin) {
    return null
  }

  return <AppRooms />
}

export default AppRoomsWrapper
