import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { getComponentId, getSelectedObjects } from 'ducks/editor/objects'
import Icon from 'components/Shared/Icon'
import SizeDropdown, { DEVICE_ICONS_LARGE } from './SizeDropdown'

const DEVICE_ICONS_SMALL = {
  mobile: <Icon type="mobile-small" />,
  tablet: <Icon type="tablet-small" />,
  desktop: <Icon type="desktop-small" />,
}

function Header(props) {
  const {
    component,
    children,
    zoomScale,
    zoomActive,
    onEnterTitle,
    onLeaveTitle,
    onClickTitle,
    app,
    deviceType,
    widthScaled,
    expanded,
    setExpanded,
    activeScreen,
    smallHeader,
    deviceIcons,
  } = props

  if (!component || zoomActive || widthScaled < 22) {
    return null
  }
  const transformStyles = {
    transform: `scale(${1 / zoomScale})`,
    transformOrigin: 'bottom left',
    transition: 'transform .1s',
    width: `${100 * zoomScale}%`,
  }

  if (!app?.magicLayout) {
    return <div style={transformStyles}>{children}</div>
  }

  const shouldShowDeviceTypeDropdown = app.webSettings?.layoutMode !== 'mobile'

  if (zoomScale < 0.08) {
    if (expanded) {
      setExpanded(false)
    }

    return (
      <div
        className={classNames('small-responsive-header', {
          'active-screen': activeScreen,
        })}
        style={transformStyles}
      >
        {children}
      </div>
    )
  }

  return (
    <div
      className={classNames('responsive-header-container', {
        'medium-chip': smallHeader,
      })}
      style={transformStyles}
    >
      <div
        className="responsive-screen-header"
        onMouseEnter={onEnterTitle}
        onMouseLeave={onLeaveTitle}
      >
        <div
          className={classNames('responsive-screen-selector', {
            'active-screen': activeScreen,
          })}
        >
          {shouldShowDeviceTypeDropdown && (
            <div
              className="device-icon"
              onMouseDown={() => setExpanded(!expanded)}
            >
              {deviceIcons[deviceType]}
            </div>
          )}
          {children}
        </div>
        <div className="screen-selector" onMouseDown={onClickTitle} />
      </div>
      <SizeDropdown
        expanded={expanded}
        setExpanded={setExpanded}
        deviceType={deviceType}
        component={component}
        smallHeader={smallHeader}
      />
    </div>
  )
}

const mapStateToProps = (state, { component, zoomScale }) => {
  const objects = getSelectedObjects(state)
  const screenIds = objects.map(object => getComponentId(state, object.id))
  const smallHeader = zoomScale < 0.4
  const deviceIcons = smallHeader ? DEVICE_ICONS_SMALL : DEVICE_ICONS_LARGE

  return {
    activeScreen: screenIds.includes(component?.id),
    smallHeader,
    deviceIcons,
  }
}

export default connect(mapStateToProps)(Header)
