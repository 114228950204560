import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getBoundingBox, getDeviceType } from '@adalo/utils'
import { COMPONENT, visibility, GROUP } from '@adalo/constants'

import getDeviceObject from 'utils/getDeviceObject'
import { roundObject } from 'utils/snapping'

import {
  selectObjects,
  getSelectedObjects,
  getObjectPosition,
  getZoom,
  resizeObject,
  updateObject,
  getComponent,
  getParentComponent,
  getCurrentAppId,
} from 'ducks/editor/objects'

import {
  getXGrid,
  getYGrid,
  setXSnap,
  setYSnap,
  resetSnaps,
} from 'ducks/editor/snapping'

import { getApp } from 'ducks/apps'

import {
  recalculateSnapGrid,
  resetSelectionParent,
} from 'ducks/editor/selection'

import { getEditingText } from 'ducks/editor/textEditing'
import { getEditingShape } from 'ducks/editor/shapeEditing'

import BoundingBox from './BoundingBox'
import './BoundingBoxes.css'

class BoundingBoxes extends Component {
  handleResizeEnd = () => {
    const { objects, resizeObject, resetSelectionParent, recalculateSnapGrid } =
      this.props

    if (objects.length !== 1) {
      return
    }

    const object = roundObject(objects[0])

    if (object?.screenResizing) {
      // screenResizing should have the false value briefly for the editor, but we don't want to save it to the database
      updateObject(object.id, { screenResizing: false }, true)
      delete object.screenResizing
    }

    recalculateSnapGrid()
    resizeObject(object.id, object)
    resetSelectionParent()
  }

  handleChange = changes => {
    const { objects, resizeObject, magicLayout, webSettings } = this.props
    const { MOBILE_BREAKPOINT } = visibility

    if (objects.length !== 1) {
      return
    }

    const object = objects[0]

    if (
      changes.width >= MOBILE_BREAKPOINT &&
      object.width < MOBILE_BREAKPOINT &&
      webSettings?.layoutMode === 'mobile'
    ) {
      // We don't exceed the mobile breakpoint when in mobile mode
      return
    }

    const { id, type, children } = object

    const isResponsiveScreen = magicLayout && type === COMPONENT && children

    if (isResponsiveScreen) {
      changes.screenResizing = true
    }

    return resizeObject(id, changes, true)
  }

  render() {
    const {
      editing,
      objects,
      zoom,
      links,
      linkTargets,
      xGrid,
      yGrid,
      setXSnap,
      setYSnap,
      resetSnaps,
      parentGroupObject,
      magicLayout,
      positioningObjects,
    } = this.props

    const boundingBox = getBoundingBox(
      objects.map(o => ({
        ...o,
        ...o.absolutePosition,
      }))
    )

    if (boundingBox) {
      boundingBox.absolutePosition = boundingBox
    }

    if (editing || !boundingBox) {
      return null
    }

    const shouldShowGroupBoundingBox =
      magicLayout &&
      positioningObjects &&
      parentGroupObject &&
      parentGroupObject?.type === GROUP

    return (
      <g className="bounding-boxes">
        {objects.map(obj => (
          <React.Fragment key={obj.id}>
            <BoundingBox
              resize={false}
              key={obj.id}
              object={obj}
              zoom={zoom}
              link={links[obj.id]}
              linkTargets={linkTargets}
              magicLayout={magicLayout}
            />
          </React.Fragment>
        ))}
        <BoundingBox
          link={objects.length === 1 ? links[objects[0].id] : null}
          resize={objects.length === 1}
          onChange={this.handleChange}
          onResizeEnd={this.handleResizeEnd}
          object={objects.length === 1 ? objects[0] : boundingBox}
          xGrid={xGrid}
          yGrid={yGrid}
          setXSnap={setXSnap}
          setYSnap={setYSnap}
          resetSnaps={resetSnaps}
          zoom={zoom}
          magicLayout={magicLayout}
        />
        {shouldShowGroupBoundingBox ? (
          <BoundingBox
            link={objects.length === 1 ? links[parentGroupObject.id] : null}
            resize={objects.length === 1}
            onChange={this.handleChange}
            onResizeEnd={this.handleResizeEnd}
            object={parentGroupObject}
            xGrid={xGrid}
            yGrid={yGrid}
            setXSnap={setXSnap}
            setYSnap={setYSnap}
            resetSnaps={resetSnaps}
            zoom={zoom}
            shouldShowGroupBoundingBox={shouldShowGroupBoundingBox}
            magicLayout={magicLayout}
          />
        ) : null}
      </g>
    )
  }
}

const mapStateToProps = state => {
  // Uncomment to enable arrows:
  // let selection = getSelection(state)
  // let links = getLinks(state, match.params.appId, selection)
  const links = {}

  const linkTargets = selectObjects(
    state,
    Object.keys(links).map(id => links[id].target)
  )

  let selectedObjects = getSelectedObjects(state)

  selectedObjects = selectedObjects.map(object => {
    const component = getComponent(state, object.id)
    const deviceType = getDeviceType(component?.width)

    return getDeviceObject(object, deviceType)
  })

  const objects = selectedObjects.map(obj => ({
    ...obj,
    absolutePosition: getObjectPosition(state, obj.id),
  }))

  const parentGroupObject = getParentComponent(state, objects[0]?.id)

  if (parentGroupObject && parentGroupObject.type === GROUP) {
    parentGroupObject.absolutePosition = getObjectPosition(
      state,
      parentGroupObject.id
    )
  }

  const appId = getCurrentAppId(state)

  const app = getApp(state, appId)

  const webSettings = app?.webSettings ?? {}

  return {
    links,
    linkTargets,
    originalObjects: selectedObjects,
    objects,
    editing: !!(getEditingText(state) || getEditingShape(state)),
    zoom: getZoom(state),
    xGrid: getXGrid(state),
    yGrid: getYGrid(state),
    parentGroupObject,
    webSettings,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    resizeObject,
    resetSelectionParent,
    recalculateSnapGrid,
    setXSnap,
    setYSnap,
    resetSnaps,
  })(BoundingBoxes)
)
