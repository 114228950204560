import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { isEmpty as _isEmpty } from 'lodash'

import EmptyState from 'components/Shared/EmptyState'
import Loading from 'components/Shared/Loading'

import {
  getOrganizations,
  updateOrganizationName,
  fetchOrganizations,
  getCurrentOrganization,
  fetchCycleAppActionsByApp,
  fetchCyclesAppActions,
} from 'ducks/organizations'

import UsageSection from './UsageSection'
import FreePlanFlow from './FreeFlowSection'
import PlanAndServices from './BillingSection/PlansAndServices'
import PaymentMethods from './BillingSection/PaymentMethods'

const BillingSub = props => {
  const { organization, onReload, appId } = props

  if (_isEmpty(organization)) {
    return null
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCyclesAppActions(organization.id))
    dispatch(fetchCycleAppActionsByApp(organization.id, organization.planType))
  }, [])

  if (!organization.active) {
    return (
      <div className="team-settings-sub-free">
        <FreePlanFlow />
        <UsageSection organization={organization} appId={appId} />
      </div>
    )
  }

  const showUsageSection = organization.planType !== 'trial'

  return (
    <div className="team-settings-sub">
      {showUsageSection && (
        <UsageSection organization={organization} appId={appId} />
      )}
      <h3 className="settings-header">Billing</h3>
      <div className="settings-content-box">
        <PlanAndServices organization={organization} />
        <hr />
        <PaymentMethods organization={organization} onSuccess={onReload} />
      </div>
    </div>
  )
}

const Billing = ({ fetchOrganizations, flags, currentOrganization, match }) => {
  const fetchData = () => {
    fetchOrganizations()
  }

  useEffect(() => {
    fetchData()
  }, [])

  const {
    params: { appId },
  } = match

  const org = currentOrganization

  return (
    <div className="team-settings">
      {org ? (
        [
          <BillingSub
            key={org.id}
            organization={org}
            updateOrganizationName={updateOrganizationName}
            onReload={fetchData}
            flags={flags}
            appId={appId}
          />,
        ]
      ) : (
        <EmptyState className="usage-section">
          <Loading />
        </EmptyState>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  organizations: getOrganizations(state),
  currentOrganization: getCurrentOrganization(state),
})

export default connect(mapStateToProps, {
  updateOrganizationName,
  fetchOrganizations,
})(Billing)
