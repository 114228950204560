import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import DocumentEvents from 'react-document-events'
import * as Sentry from '@sentry/browser'
import moment from 'moment'
import { toast, cssTransition } from 'react-toastify'
import userflow from 'userflow.js'
import { DeviceBreakpoint, DeviceWidth } from '@adalo/constants'

import { getAppTitle } from 'utils/titles'
import Session from 'utils/session'
import { setDocumentTitle } from 'utils/browsers'
import { verifyXanoApp } from 'utils/externalDatabases'
import centerScreenOnCanvas from 'utils/objects/centerScreenOnCanvas'
import { joinAppRoom } from 'utils/io'

import { getApp, getUpgraded } from 'ducks/apps'
import { getDatasources, getDefaultDatasource } from 'ducks/apps/datasources'
import { requestData, resizeObject } from 'ducks/editor/objects'
import { fetchCustomActions } from 'ducks/customActions'
import { fetchApiKey } from 'ducks/apikeys'
import { fetchThirdPartyApiKeys } from 'ducks/thirdPartyApiKeys'
import {
  getOrganization,
  getTrialState,
  fetchOrganization,
  setCurrentOrganization,
  getCurrentOrganizationId,
} from 'ducks/organizations'
import {
  ensureLicensesAreLoaded,
  getLicenses,
} from 'ducks/marketplace/licenses'
import { showModal, TERMS_OF_SERVICE_MODAL } from 'ducks/editor/modals'
import { getCurrentUser } from 'ducks/users'
import { fetchFeatureTemplates } from 'ducks/featureTemplates'
import { resetSelection } from 'ducks/editor/selection'

import EmptyState from 'components/Shared/EmptyState'
import Loading from 'components/Shared/Loading'
import { Panel, PanelSection } from 'components/Shared/Panel'
import TrialWarning from 'components/Shared/TrialWarning'
import UserQuestionsModal from 'components/Auth/UserQuestions'
import Button from 'components/Shared/Button'
import { AFTER, SEEN_END_TRIAL_SESSION_KEY } from '../../constants'

import DatasourceItem from '../AppDetail/Data/DatasourceItem'

import Canvas from './Canvas'
import Clipboard from './Clipboard'
import DebugPanel from './Debug/DebugPanel'
import LeftPanel from './LeftPanel'
import LeftNav from './LeftNav'
import LibraryLoader from './LibraryLoader'
import PrebuiltLayoutSectionsLoader from './PrebuiltLayoutSectionsLoader'
import { Data, Screens, Marketplace } from './Routes'
import ExternalUsersModal from './ExternalUsersModal'
import XanoExternalDatabaseModal from './XanoExternalDatabaseModal'
import ModalWrapper from './ModalWrapper'
import FontsLoader from './FontsLoader'
import IntegrationTrialEndModal from './IntegrationTrialEndModal'
import FreeTrialEndModal from './FreeTrialEndModal'
import FreeTrialExtendModal from './FreeTrialExtendModal'
import AppQuestionsModal from './Publish/AppQuestionsModal'
import MobileBlocker from './MobileBlocker'

import AppMagicLayoutContext from './AppMagicLayoutContext'

import './Editor.scss'

export { default as EditorNav } from '../Nav'

class Editor extends Component {
  state = {
    error: null,
    librariesReady: false,
    userQuestionsEnabled: false,
  }

  handleWheel = e => {
    if (e.ctrlKey) {
      e.preventDefault()
    }
  }

  handleLibrariesLoad = librariesReady => {
    this.setState({ librariesReady })
  }

  isEditorLoad = () => {
    const { location } = this.props

    const isCloningApp =
      location.pathname.includes('/screens/screens') ||
      location.pathname.includes('/screens/data')
    const isCopyingApp = location.pathname.includes('/screens/copy')

    if (
      location.pathname.includes('/screens') &&
      !(isCloningApp || isCopyingApp)
    ) {
      return true
    }

    return false
  }

  verifyUserQuestionsSnooze = () => {
    const { currentUser } = this.props

    if (!currentUser?.persona && !currentUser?.admin && this.isEditorLoad()) {
      const userQuestionsSnooze = localStorage.getItem('userQuestionsSnooze')

      if (userQuestionsSnooze) {
        const { date } = JSON.parse(userQuestionsSnooze)

        const days = moment().diff(moment(date), 'days')

        if (days > 0) {
          this.setState({ userQuestionsEnabled: true })
        }
      } else {
        this.setState({ userQuestionsEnabled: true })
      }
    }
  }

  loadApp = () => {
    const {
      match,
      requestData,
      fetchCustomActions,
      app,
      fetchApiKey,
      ensureLicensesAreLoaded,
      fetchThirdPartyApiKeys,
      showModal,
      location,
      fetchFeatureTemplates,
      currentUser,
    } = this.props

    const { appId } = match.params

    requestData(appId)
    fetchCustomActions(appId)
    fetchFeatureTemplates(appId)
    fetchApiKey(appId)
    ensureLicensesAreLoaded(app?.OrganizationId, appId)

    if (currentUser?.email) {
      userflow
        .updateUser({
          current_app_id: appId,
        })
        .catch(err => {
          console.error(`Failed to update userflow user: ${err.message}`)
          Sentry.captureMessage('ERROR UPDATING USERFLOW USER', {
            user: {
              email: currentUser.email,
            },
            level: 'error',
            extra: {
              error: err.message,
            },
          })
        })
    }

    if (app) {
      fetchOrganization(app.OrganizationId)
    }

    fetchThirdPartyApiKeys(appId)
    document.body.classList.add('editor-body')

    if (app && app.Organization && !app.Organization.active) {
      document.body.classList.add('editor-app-unpaid')
      this.addedUnpaidClass = true
    }

    if (location.search.includes('terms=')) {
      showModal(TERMS_OF_SERVICE_MODAL)
    }

    joinAppRoom(appId)
    this.verifyUserQuestionsSnooze()
  }

  componentDidMount() {
    this.loadApp()
  }

  componentWillUnmount() {
    document.body.classList.remove('editor-body')
    document.body.classList.remove('editor-app-unpaid')
  }

  componentDidUpdate(prevProps) {
    const {
      app,
      ensureLicensesAreLoaded,
      location,
      match,
      showModal,
      organization,
      setCurrentOrganization,
      fetchOrganization,
      currentOrganizationId,
      trialState,
      currentUser,
      resizeObject,
    } = this.props

    if (prevProps.match.params.appId !== match.params.appId) {
      // can happen when the maker is using the browser back button
      // the component is recycled and only componentDidUpdate is called
      this.loadApp()
    }
    if (!location?.pathname.includes('settings')) {
      setDocumentTitle(getAppTitle(app))
    }

    document.body.classList.remove('editor-app-unpaid')
    this.addedUnpaidClass = false

    if (app?.OrganizationId && trialState !== prevProps?.trialState) {
      fetchOrganization(app.OrganizationId)
    }

    if (app && app.OrganizationId !== prevProps?.app?.OrganizationId) {
      ensureLicensesAreLoaded(app?.OrganizationId, app.id)
    }

    if (app && app?.webSettings) {
      const mobileOnly = app.webSettings?.layoutMode === 'mobile'

      const shouldResizeFeatureTemplateScreens = localStorage.getItem(
        'shouldResizeFeatureTemplateScreens'
      )

      const shouldResizeAppTemplateScreens = localStorage.getItem(
        'shouldResizeAppTemplateScreens'
      )

      const shouldResizeScreens =
        shouldResizeFeatureTemplateScreens || shouldResizeAppTemplateScreens

      if (mobileOnly && shouldResizeScreens) {
        for (const [componentId, component] of Object.entries(app.components)) {
          if (component.width > DeviceBreakpoint.MOBILE_BREAKPOINT) {
            resizeObject(componentId, {
              width: DeviceWidth.DESKTOP_DEFAULT_WIDTH,
            })
          }
        }

        localStorage.removeItem('shouldResizeFeatureTemplateScreens')
        localStorage.removeItem('shouldResizeAppTemplateScreens')
      }
    }

    if (organization && organization.id !== currentOrganizationId) {
      setCurrentOrganization(organization.id)
      fetchOrganization(organization.id)
    }

    if (prevProps.location.search !== location.search) {
      if (location.search.includes('terms=')) {
        showModal(TERMS_OF_SERVICE_MODAL)
      }
    }

    if (prevProps.currentUser?.persona !== currentUser?.persona) {
      this.verifyUserQuestionsSnooze()
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })

    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })

    Sentry.captureException(error)
  }

  hasAppQuestionsSnoozed = () => {
    const appQuestionsSnooze = localStorage.getItem('appQuestionsSnooze')

    if (this.isEditorLoad()) {
      if (appQuestionsSnooze) {
        const { date } = JSON.parse(appQuestionsSnooze)

        const days = moment().diff(moment(date), 'days')

        if (days > 0) {
          return false
        }
      } else {
        return false
      }
    }

    return true
  }

  notify = (featureName, preview) => {
    toast(
      <>
        <div>
          <h2>
            {featureName} <span>Feature Added!</span>
          </h2>
          <p>Read through the Instructions Screen to get started.</p>
        </div>
        <Button yellow outlined to={preview} target="_blank">
          Learn More
        </Button>
      </>,
      {
        position: 'bottom-right',
        className: 'feature-templates-cta__toast',
        bodyClassName: 'feature-templates-cta__toast-body',
        hideProgressBar: true,
        autoClose: 5000,
        transition: cssTransition({
          enter: 'animate__animated animate__fadeInUp',
          exit: 'animate__animated animate__fadeOutDown',
          collapse: false,
        }),
      }
    )
  }

  render() {
    const { librariesReady, error, userQuestionsEnabled } = this.state

    const {
      app,
      match,
      children,
      datasource,
      showEndFreeTrialModal,
      licenses,
      currentUser,
      OrganizationId,
      magicLayout,
      centerScreenOnCanvas,
      resetSelection,
    } = this.props

    const { appId } = match.params

    if (error) {
      return (
        <div className="editor-error">
          <EmptyState greedy>
            <h2>Oh no!</h2>
            <p>An error occurred.</p>
            <p>Please reload the page and try again.</p>
          </EmptyState>
          {children}
        </div>
      )
    }

    if (
      datasource?.type === 'api' &&
      window.location.pathname.includes('/data')
    ) {
      return (
        <AppMagicLayoutContext.Provider value={{ hasMagicLayout: magicLayout }}>
          <APIData {...this.props} />
        </AppMagicLayoutContext.Provider>
      )
    }

    const showExternalUsersModal =
      app?.externalUsers &&
      app?.externalUsers?.enabled &&
      app?.externalUsers?.provider !== 'xano' &&
      !app?.externalUsers?.setupStatus.complete

    const showXanoExternalDatabaseModal =
      verifyXanoApp(app) && !app?.externalUsers?.setupStatus.complete

    const hideExternalUsersModal = showExternalUsersModal === false

    const hideExternalDatabaseModal = showXanoExternalDatabaseModal === false

    const showUserQuestionsModal =
      (userQuestionsEnabled &&
        hideExternalUsersModal &&
        hideExternalDatabaseModal) ||
      (userQuestionsEnabled && app && !app?.externalUsers)

    const showWebAppQuestionsModal =
      !this.hasAppQuestionsSnoozed() &&
      app?.primaryPlatform === 'web' &&
      !magicLayout &&
      !app?.type &&
      !currentUser?.admin &&
      !showUserQuestionsModal

    const showExternalDatabaseRoute = verifyXanoApp(app)

    let canvasContent = (
      <EmptyState className="editor-body-loader">
        <Loading large />
      </EmptyState>
    )

    if (librariesReady) {
      canvasContent = <Canvas appId={appId} magicLayout={magicLayout} />
    }

    const shouldShowToast = localStorage.getItem('showFeatureTemplatesToast')

    if (app && shouldShowToast) {
      const { name, preview, instructionsScreenId } =
        JSON.parse(shouldShowToast)

      // TODO @danicunhac: Research on how to make this work without hardcoded values
      const PADDING_X = -600
      const PADDING_Y = -600
      const OFFSET_X = -600
      const OFFSET_Y = 50

      const instructionsScreen = app?.components?.[instructionsScreenId]
      centerScreenOnCanvas(
        instructionsScreen,
        PADDING_X,
        PADDING_Y,
        OFFSET_X,
        OFFSET_Y
      )
      resetSelection()

      this.notify(name, preview)
      localStorage.removeItem('showFeatureTemplatesToast')
    }

    return (
      <AppMagicLayoutContext.Provider value={{ hasMagicLayout: magicLayout }}>
        <div>
          {OrganizationId && (
            <TrialWarning organizationId={OrganizationId} appId={appId} />
          )}
          <div className="editor">
            {app ? (
              <>
                <LibraryLoader
                  app={app}
                  onLoad={this.handleLibrariesLoad}
                  licenses={licenses}
                />
                <FontsLoader app={app} />
                <PrebuiltLayoutSectionsLoader />
              </>
            ) : null}
            <Clipboard appId={appId} />
            <DocumentEvents
              capture
              onWheel={this.handleWheel}
              passive={false}
            />
            {canvasContent}
            <LeftPanel magicLayout={magicLayout} />
            <LeftNav />

            <DebugPanel />
            <Routes
              showExternalDatabaseRoute={showExternalDatabaseRoute}
              appId={appId}
            />
            <ModalWrapper />
            {showEndFreeTrialModal ? (
              <Redirect to={`/apps/${appId}/free-trial-end`} />
            ) : null}
            {showExternalUsersModal ? (
              <Redirect to={`/apps/${appId}/external-users-setup`} />
            ) : null}
            {showXanoExternalDatabaseModal ? (
              <Redirect to={`/apps/${appId}/external-database/setup`} />
            ) : null}
            {showUserQuestionsModal ? (
              <Redirect to={`/apps/${appId}/existing-user-questions`} />
            ) : null}
            {showWebAppQuestionsModal ? (
              <Redirect to={`/apps/${appId}/existing-app-questions`} />
            ) : null}
          </div>
          {children}
        </div>
      </AppMagicLayoutContext.Provider>
    )
  }
}

const mapStateToProps = (state, { match }) => {
  const { appId } = match.params
  const app = getApp(state, appId)

  const { OrganizationId, magicLayout } = app || {}
  const organization = getOrganization(state, OrganizationId)
  const currentOrganizationId = getCurrentOrganizationId(state)
  const licenses = getLicenses(state, OrganizationId, appId)

  const { seenEndIntegrationTrial } = organization || {}

  const paying = getUpgraded(state, appId)
  const { trialState } = getTrialState(state)

  const seenEndTrialModal =
    !Session.get(`${OrganizationId}-${SEEN_END_TRIAL_SESSION_KEY}`) &&
    !paying &&
    trialState === AFTER &&
    !seenEndIntegrationTrial &&
    organization

  return {
    app,
    organization,
    currentOrganizationId,
    datasources: getDatasources(state, appId),
    datasource: getDefaultDatasource(state, appId),
    showEndFreeTrialModal: seenEndTrialModal,
    licenses,
    trialState,
    currentUser: getCurrentUser(state),
    OrganizationId,
    magicLayout: magicLayout || false,
  }
}

export default connect(mapStateToProps, {
  requestData,
  fetchCustomActions,
  fetchApiKey,
  ensureLicensesAreLoaded,
  fetchThirdPartyApiKeys,
  setCurrentOrganization,
  fetchOrganization,
  showModal,
  fetchFeatureTemplates,
  centerScreenOnCanvas,
  resetSelection,
  resizeObject,
})(Editor)

const Routes = ({ showExternalDatabaseRoute, appId }) => {
  return (
    <Switch>
      <Route path="/apps/:appId/screens" component={Screens} />
      <Route path="/apps/:appId/data" component={Data} />
      <Route path="/apps/:appId/marketplace" component={Marketplace} />
      <Route
        path="/apps/:appId/external-users-setup"
        component={ExternalUsersModal}
      />
      <Route
        path="/apps/:appId/external-database/setup"
        component={
          showExternalDatabaseRoute
            ? XanoExternalDatabaseModal
            : () => <Redirect to={`/apps/${appId}/screens`} />
        }
      />
      <Route
        path="/apps/:appId/existing-user-questions"
        component={UserQuestionsModal}
      />
      <Route
        path="/apps/:appId/existing-app-questions"
        component={AppQuestionsModal}
      />
      <Route
        path="/apps/:appId/integration-trial-end"
        component={IntegrationTrialEndModal}
      />
      <Route path="/apps/:appId/free-trial-end" component={FreeTrialEndModal} />
      <Route
        path="/apps/:appId/free-trial-extend"
        component={FreeTrialExtendModal}
      />
      <Route path="/apps/:appId/mobile-blocker" component={MobileBlocker} />
    </Switch>
  )
}

const APIData = ({ app, datasources, match }) => {
  const { appId } = match.params

  const title = getAppTitle(app)

  useEffect(() => {
    setDocumentTitle(title)
  }, [title])

  return (
    <div className="data">
      <LeftNav />
      <Panel className="data-sidebar api">
        <PanelSection>
          <ul className="data-sidebar-datasources">
            {datasources.map(datasource => (
              <DatasourceItem
                key={datasource.id}
                appId={appId}
                datasource={datasource}
                datasourceId={datasource.id}
              />
            ))}
          </ul>
        </PanelSection>
      </Panel>
      <div className="data-body api">
        <Routes />
      </div>
    </div>
  )
}
