import React, { useState } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { runInstructions, getMap, getObjectList } from 'ducks/editor/objects'
import { openAccordion } from 'ducks/accordions'
import {
  disableDeviceSpecificLayout,
  enableDeviceSpecificLayout,
  resetSharedLayout,
} from 'ducks/editor/instructions'
import { DEFAULT_DEVICE_SETTINGS } from 'utils/defaultDeviceSettings'
import {
  getLayoutSettingsGroup,
  getLayoutSettingsItemId,
} from 'utils/accordionGroups'
import useSetScreenDevice from 'hooks/editor/useSetScreenDevice'
import useObjectScreenDevice from 'hooks/editor/useObjectScreenDevice'
import Tooltip from 'components/Shared/Tooltip'
import Icon from 'components/Shared/Icon'
import {
  getSectionFromContainer,
  isContainerSectionElement,
} from 'utils/layoutSections'
import LayoutIcon from './LayoutSettingsIcon'

const LayoutSettingsHeader = props => {
  const {
    visibleIcons,
    shared,
    object,
    runInstructions,
    expanded,
    openAccordion,
  } = props

  const [tooltip, setTooltip] = useState('')
  const setScreenDevice = useSetScreenDevice(object.id)
  const currentScreenDevice = useObjectScreenDevice(object.id)

  const title = `${shared ? 'Shared' : 'Custom'} Layout`

  const onClick = device => {
    const sharedSettings = object?.shared || { ...DEFAULT_DEVICE_SETTINGS }
    const shouldEnable = Boolean(sharedSettings[device])

    let instructions = []

    if (shouldEnable) {
      instructions = [enableDeviceSpecificLayout(object.id, device)]
    } else {
      // If we're disabling the custom layout for the current device, we want to keep what we're seeing in the shared layout
      // So instead of running a regular `disableDeviceSpecificLayout`, that would leave whatever shared layout is present on the object,
      // we use `resetSharedLayout`, so the current shared layout gets overwritten with what the user is seeing on the screen.
      const isDisablingFromCurrentDevice = device === currentScreenDevice

      if (isDisablingFromCurrentDevice) {
        instructions = [resetSharedLayout(object.id, device, true)]
      } else {
        let objectId = object.id

        if (isContainerSectionElement(object)) {
          const { getSection } = props
          const section = getSection(object)

          if (section) {
            objectId = section.id
          }
        }

        instructions = [disableDeviceSpecificLayout(objectId, device)]
      }
    }

    runInstructions(instructions)

    // Open the layout accordion for that device
    const groupId = getLayoutSettingsGroup(object.id)
    const targetLayout = shouldEnable ? device : 'shared'
    const accordionItemId = getLayoutSettingsItemId(object.id, targetLayout)
    openAccordion(groupId, accordionItemId)

    if (shouldEnable) {
      // Set the parent screen to the device
      setScreenDevice(device)
    }
  }

  const addSharedWrapper =
    shared &&
    visibleIcons.length > 1 &&
    visibleIcons[1].shared &&
    (visibleIcons[0].shared || visibleIcons[2]?.shared)

  const subArrays = getSubArrays(visibleIcons, addSharedWrapper)

  return (
    <div className="layout-settings-header">
      <Icon
        type={expanded ? 'expand-vertical' : 'expand'}
        color={expanded ? 'white' : 'black'}
      />
      <p
        className={classNames('layout-settings-title', {
          'layout-settings-title-auto-layout': true,
        })}
      >
        {title}
      </p>
      <Tooltip
        tooltip={tooltip}
        placement="bottom-end"
        className="layout-icon-tooltip"
      >
        <div className="layout-icons-container">
          {subArrays.map(array =>
            renderSubArray(array, shared, onClick, setTooltip)
          )}
        </div>
      </Tooltip>
    </div>
  )
}

const getSubArrays = (visibleIcons, addSharedWrapper) => {
  const arrays = [{ icons: visibleIcons }]

  if (addSharedWrapper) {
    let secondSubArray = {}
    if (!visibleIcons[0].shared) {
      arrays[0] = {
        addWrapper: false,
        icons: [visibleIcons[0]],
      }
      secondSubArray = {
        addWrapper: true,
        icons: visibleIcons.slice(1),
      }
    } else {
      arrays[0].addWrapper = true

      if (visibleIcons[2] && !visibleIcons[2].shared) {
        secondSubArray = {
          addWrapper: false,
          icons: [visibleIcons[2]],
        }
        arrays[0].icons = visibleIcons.slice(0, 2)
      }
    }

    arrays.push(secondSubArray)
  }

  return arrays
}

const renderSubArray = (arrayObject, shared, onClick, setTooltip) => {
  const { icons, addWrapper } = arrayObject || {}
  if (arrayObject && icons && icons.length > 0) {
    const children = icons.map(icon => (
      <LayoutIcon
        icon={icon}
        shared={shared}
        onClick={onClick}
        key={`${icon.device}-icon-shared-${shared}`}
        setTooltip={setTooltip}
      />
    ))

    if (addWrapper) {
      return <div className="shared-layout-active-wrapper">{children}</div>
    }

    return children
  }
}

const mapStateToProps = state => {
  const list = getObjectList(state)
  const map = getMap(state)

  return {
    getSection: container => getSectionFromContainer(list, map, container),
  }
}

export default connect(mapStateToProps, {
  runInstructions,
  openAccordion,
})(LayoutSettingsHeader)
