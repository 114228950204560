import ViewApp from '../Editor/ViewApp'
import { Spacer } from '../App/Navbar'
import AppRooms from '../AppRooms'
import OrgAppSwitcher from './OrgAppSwitcher'

import './Nav.scss'

const EditorNav: React.FC = () => {
  return (
    <>
      <OrgAppSwitcher />
      <AppRooms />
      <Spacer />
      <ViewApp />
    </>
  )
}

export default EditorNav
