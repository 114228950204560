import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import QS from 'qs'
import { Link } from 'react-router-dom'

import { adaloBackendAxios } from 'utils/io/http/axios'
import { getDomains } from 'ducks/domains'
import { showModal, setPaymentRouteType } from 'ducks/trialWarning'

import SettingsFormField from 'components/Shared/Forms/SettingsFormField'
import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'
import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import ToggleField from 'components/Shared/Forms/ToggleField'

import DomainPicker from '../DomainPicker'

const asyncValidate = async app => {
  try {
    const query = QS.stringify({
      path: app.path || '',
      organizationId: app.Organization.id,
      appId: app.id,
      domainId: app.DomainId,
    })

    const url = `/organizations/path-availability?${query}`
    await adaloBackendAxios.get(url)
  } catch (err) {
    throw { path: `This URL is already in use by another app.` }
  }
}

const validate = ({ path }) => {
  const errors = {}

  if (path && path.match(/[^a-z\d\-_]/)) {
    errors.path = 'Can only include letters, numbers, and dashes'
  }

  return errors
}

class WebPublishForm extends Component {
  handleShowModal = e => {
    e.preventDefault()

    const { showModal, setPaymentRouteType } = this.props

    setPaymentRouteType('upgrade')
    showModal()
  }

  renderURL() {
    const { app, getAppHost } = this.props
    const magicLayout = app?.magicLayout

    // Only desktop apps should show the "Published On" url
    // responsive web apps show the url in the BuildsList
    if (magicLayout) {
      return null
    }

    const protocol = window.location.protocol
    let url = getAppHost()

    url = `${url}${app.path || ''}`

    return (
      <div className="web-publish-url-box">
        <div className="settings-form-row">
          <label>Published On</label>
          <p className="settings-form-static-value">{url}</p>
        </div>
        <div className="web-publish-open-url">
          <Button green to={`${protocol}//${url}`} target="_blank">
            Open
          </Button>
        </div>
      </div>
    )
  }

  renderWebSettings() {
    const { app, showWebSettings, showAddToHomeScreen } = this.props

    if (!app || !showWebSettings) {
      return null
    }

    const previewTypeOptions = [
      { value: 'web', label: 'Responsive App' },
      { value: 'mobile', label: 'Mobile App Preview' },
    ]

    return (
      <>
        <div className="settings-form-row">
          <label>Show Desktop Users...</label>
          <div className="settings-form-input">
            <Field
              className="settings-form-input-select"
              name="previewType"
              component={WrappedSelect}
              options={previewTypeOptions}
            />
          </div>
        </div>
        <div className="settings-form-row">
          <Field
            name="showAddToHomeScreen"
            label="Ask mobile users to add to Home Screen"
            component={ToggleField}
            inputValue={showAddToHomeScreen ? 'On' : 'Off'}
          />
        </div>
      </>
    )
  }

  render() {
    const {
      handleSubmit,
      dirty,
      submitting,
      app,
      needUpgrade,
      domainId,
      getAppHost,
      customDomainsDisabled,
      mobileOnly,
    } = this.props

    const pathLabel = getAppHost(domainId, true)

    let customDomainSettings = (
      <DomainPicker
        appId={app?.id}
        customDomainsDisabled={customDomainsDisabled}
      />
    )

    if (needUpgrade) {
      customDomainSettings = (
        <div className="settings-form-row">
          <label>Domain</label>
          <div className="">
            <p className="publish-form-note">
              In order to add a custom domain, you need to upgrade to a paid
              plan.
            </p>
            <div className="settings-form-inline-submit">
              <Button green type="button" onClick={this.handleShowModal}>
                Upgrade Now
              </Button>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <form onSubmit={handleSubmit} className="web-publish-form">
          {customDomainSettings}
          <Field
            label={pathLabel}
            name="path"
            component={SettingsFormField}
            autoComplete="off"
          />
          {!mobileOnly && this.renderWebSettings()}
          <div className="settings-form-inline-submit">
            <Button green loading={submitting} disabled={!dirty || submitting}>
              Save
            </Button>
          </div>
          {mobileOnly && (
            <div className="mobile-layout-help-text-container">
              <div className="mobile-layout-help-header">
                <Icon type="help-text" />
                &nbsp;
                <h2>Helpful Tip</h2>
              </div>
              <h3>
                What will show up for a mobile-only app on different device
                types?
              </h3>
              <br />
              <p>
                On desktop & tablet devices, users will see a preview of your
                app inside of a phone screen.
                <br />
                <br />
                On mobile on the web, users will first see the option to add
                their app to their home screen as a bookmark and then will be
                able to experience the full app on their phone.
                <br />
                <br />
                <Link
                  to={`/apps/${app.id}/app-settings?active=layout`}
                  className="help-doc"
                >
                  Settings
                </Link>
                .
              </p>
            </div>
          )}
        </form>
        {this.renderURL()}
      </div>
    )
  }
}

const selector = formValueSelector('web-publish-settings')

const mapStateToProps = (state, { app }) => {
  const previewType = selector(state, 'previewType')
  const mobileOnly = app.webSettings?.layoutMode === 'mobile'

  return {
    domainId: selector(state, 'DomainId'),
    domains: getDomains(state, app.Organization.id),
    previewType: previewType === 'mobile' ? 'mobile' : 'web',
    showAddToHomeScreen: selector(state, 'showAddToHomeScreen'),
    mobileOnly,
  }
}

export default connect(mapStateToProps, { showModal, setPaymentRouteType })(
  reduxForm({
    form: 'web-publish-settings',
    enableReinitialize: true,
    asyncValidate,
    validate,
  })(WebPublishForm)
)
