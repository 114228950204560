import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import {
  dataTypes,
  formatterOptions,
  richObjectDataTypes,
  numberFormats,
} from '@adalo/constants'
import DocumentEvents from 'react-document-events'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { getLabel, getShortLabel } from 'utils/sources'
import { get } from 'utils/optimistic-get'
import { capitalize } from 'utils/strings'
import { currencies } from 'utils/currency'

import { getLibraryBindingSuggestions } from 'ducks/recommender'
import { selectObject } from 'ducks/editor/objects'

import SimpleTextField from 'components/Shared/Forms/SimpleTextField'
import WrappedSelect from '../Forms/WrappedSelect'
import Icon, { IconButton } from '../Icon'
import PrefixSuffix from './PrefixSuffix'
import ListFilters from '../../Editor/Inspect/Libraries/ListFilters'
import FormulaControl from './FormulaControl'

import './BindingEntity.css'

const POPOUT_WIDTH = 320

class EntityDetail extends Component {
  handleResize = () => {
    const { onClose } = this.props

    onClose()
  }

  handleChangeBinding = newValue => {
    const { entity, onUpdate } = this.props
    const { dataType } = newValue.source
    let { format } = entity

    if (!format || entity.source.dataType !== dataType) {
      const options = formatterOptions[dataType] || []
      const option = options[0]
      format = option ? { type: option.value } : undefined
    }

    onUpdate({ ...newValue, format })
  }

  handleChangeFilter = filter => {
    const { entity, onUpdate } = this.props

    if (entity.source.dataType === dataTypes.LIST) {
      onUpdate({
        ...entity,
        source: {
          ...entity.source,
          options: {
            ...entity.source.options,
            filter,
          },
        },
      })
    } else if (entity.source.source) {
      onUpdate({
        ...entity,
        source: {
          ...entity.source,
          source: {
            ...entity.source.source,
            options: {
              ...entity.source.source.options,
              filter,
            },
          },
        },
      })
    }
  }

  handleChangeFormatter = type => {
    let { entity, onUpdate } = this.props

    entity = { ...entity, format: { ...entity?.format, type } }

    onUpdate(entity)
  }

  handleChangeFormula = ({ formula }) => {
    const { onUpdate, entity } = this.props
    const { format } = entity

    onUpdate({ ...formula, format })
  }

  getPortalNode = () => {
    if (!this.portalNode) {
      this.portalNode = document.createElement('div')
      this.portalNode.className = 'entity-detail-portal'
      document.body.appendChild(this.portalNode)
    }

    return this.portalNode
  }

  renderFilter = () => {
    const { appId, componentId, object, entity, reference } = this.props

    if (
      entity.source.dataType !== dataTypes.LIST &&
      (!entity.source.source ||
        entity.source.source.dataType !== dataTypes.LIST)
    ) {
      return
    }

    const binding =
      entity.source.dataType === dataTypes.LIST ? entity : entity.source

    const bindingId = entity.id

    return (
      <div className="entity-detail-popout-section">
        <p className="entity-detail-popout-label">Filter</p>
        <ListFilters
          appId={appId}
          componentId={componentId}
          object={object}
          binding={binding}
          bindingId={bindingId}
          onChange={this.handleChangeFilter}
          addLabel="Add Custom Filter"
          fitsParentWidth
          reference={reference}
        />
      </div>
    )
  }

  renderFormatter = () => {
    const { entity } = this.props
    const parentSource = entity.source?.source

    if (entity.source?.fieldId === 'id') {
      return null
    }

    let dataType
    let dataSubType
    let field

    if (parentSource && richObjectDataTypes.includes(parentSource.dataType)) {
      // prettier-ignore
      dataType = parentSource.dataType;

      // prettier-ignore
      [dataSubType, field] = entity.source.fieldId.split('.')
    } else if (entity.type === 'formula') {
      dataType = dataTypes.NUMBER
    } else {
      dataType = entity.source.dataType
    }

    let typeName = capitalize(dataType)
    let options = formatterOptions[dataType] || []

    if (dataSubType) {
      typeName = capitalize(dataSubType)
      options = options[dataSubType] || []
    }

    if (field) {
      typeName = capitalize(field)
      options = options[field] || []
    }

    if (dataType === dataTypes.DATE_ONLY) {
      typeName = 'Date'
    }

    const value = get(entity, 'format.type')

    if (!options || options.length === 0) {
      return null
    }

    const { CURRENCY, ABBREVIATED_CURRENCY } = numberFormats

    return (
      <div className="entity-detail-popout-section">
        <p className="entity-detail-popout-label">{`${typeName} Format`}</p>
        <WrappedSelect
          options={options}
          value={value}
          onChange={this.handleChangeFormatter}
          placeholder="None"
        />

        {value === CURRENCY || value === ABBREVIATED_CURRENCY
          ? this.renderCurrency()
          : null}
      </div>
    )
  }

  handleCurrencyChange = currency => {
    let { entity, onUpdate } = this.props
    entity = { ...entity, format: { ...entity?.format, currency } }

    return onUpdate(entity)
  }

  renderCurrency = () => {
    const { entity } = this.props
    const value = get(entity, 'format.currency') || 'usd'

    return (
      <>
        <p className="entity-detail-popout-label">Currency Type</p>
        <WrappedSelect
          options={currencies}
          value={value}
          onChange={this.handleCurrencyChange}
        />
      </>
    )
  }

  handleTestValueChange = testValueObj => {
    let { entity, onUpdate } = this.props
    const { testValue } = testValueObj
    entity = { ...entity, testValue }

    return onUpdate(entity)
  }

  renderTestValue = () => {
    const { enableTestValue, entity } = this.props
    if (!enableTestValue) return null

    const { testValue } = entity

    return (
      <SimpleTextField
        label="Test Value"
        value={testValue}
        onChange={this.handleTestValueChange}
        name="testValue"
      />
    )
  }

  renderSub() {
    const { label, entity, onUpdate } = this.props

    const isNumberDataType = entity.source?.dataType === dataTypes.NUMBER
    const isRichObjectDataType = richObjectDataTypes.includes(
      entity.source?.source?.dataType
    )

    const showPrefixSuffix =
      isNumberDataType &&
      !isRichObjectDataType &&
      entity.source?.fieldId !== 'id'

    return (
      <React.Fragment>
        <h4 className="entity-detail-popout-header">{label}</h4>
        {this.renderFilter()}
        {this.renderFormatter()}
        {this.renderTestValue()}
        {showPrefixSuffix ? (
          <PrefixSuffix entity={entity} onUpdate={onUpdate} />
        ) : null}
      </React.Fragment>
    )
  }

  renderFormula() {
    const {
      entity,
      objectId,
      role,
      reference,
      onUpdate,
      object,
      hideParentListOptions,
    } = this.props

    const { actionId } = object.attributes?.action || {}

    return (
      <React.Fragment>
        <h2>Custom Formula</h2>
        <FormulaControl
          contained
          name="formula"
          onChange={this.handleChangeFormula}
          value={entity}
          objectId={objectId}
          role={role}
          reference={reference}
          actionId={actionId}
          hideParentListOptions={hideParentListOptions}
        />
        {this.renderFormatter()}
        <PrefixSuffix entity={entity} onUpdate={onUpdate} />
      </React.Fragment>
    )
  }

  render() {
    const { left, top, bottom, onClose, entity, onDelete, fromModal } =
      this.props

    const styles = {
      left,
      top,
      bottom,
    }

    const className = top
      ? 'entity-detail-popout-below'
      : 'entity-detail-popout-above'

    return ReactDOM.createPortal(
      <div
        className={
          fromModal ? 'entity-detail-wrapper-modal' : 'entity-detail-wrapper'
        }
      >
        <DocumentEvents onResize={this.handleResize} target={window} />
        <div className="entity-detail-backdrop" onMouseDown={onClose} />
        <div
          className={classNames('entity-detail-popout', className)}
          style={styles}
        >
          <div className="entity-detail-popout-sub">
            <div className="entity-detail-popout-delete">
              <IconButton light type="trash-small" onClick={onDelete} />
            </div>
            {entity && entity.type === 'formula'
              ? this.renderFormula()
              : this.renderSub()}
          </div>
        </div>
      </div>,
      this.getPortalNode()
    )
  }
}

const detailMapState = (state, props) => ({
  label: getLabel(state, props.entity.source, props.appId, props.componentId),
  object: selectObject(state, props.objectId),
  bindingOptions: getLibraryBindingSuggestions(
    state,
    props.appId,
    props.componentId,
    props.objectId,
    [dataTypes.TEXT, dataTypes.NUMBER, dataTypes.DATE],
    props.reference
  ),
})

const WrappedEntityDetail = connect(detailMapState)(EntityDetail)

class BindingEntity extends Component {
  constructor(props) {
    super(props)

    this.state = {
      left: 100,
      top: 100,
      bottom: undefined,
      open: false,
    }

    this.entityLabel = React.createRef()
  }

  handleMouseDown = e => {
    e.preventDefault()
    const { disableChip } = this.props

    if (disableChip) {
      return
    }

    const rect = e.currentTarget.getBoundingClientRect()

    this.openPopup(rect)
  }

  openPopup = rect => {
    const left = Math.round(
      Math.max(80, (rect.left + rect.right - POPOUT_WIDTH) / 2)
    )

    let top
    let bottom

    if (window.innerHeight - rect.bottom > rect.top) {
      top = rect.bottom + 10
    } else {
      bottom = window.innerHeight - rect.top + 10
    }

    this.setState({ left, top, bottom, open: true })
  }

  componentDidMount() {
    const { enableTestValue } = this.props

    if (enableTestValue) {
      this.openPopup(this.entityLabel.current.getBoundingClientRect())
    }
  }

  handleClosePopout = () => {
    this.setState({ open: false })
  }

  render() {
    let {
      appId,
      componentId,
      objectId,
      role,
      reference,
      entity,
      label,
      onDelete,
      onUpdate,
      disableIcon,
      getLabel,
      disableChip,
      enableTestValue,
      fromModal,
      hideParentListOptions,
    } = this.props

    if (entity && entity.type === 'formula') {
      label = 'Custom Formula'
    }

    const { open, left, top, bottom } = this.state

    return (
      <span className="bindable-text-control-entity">
        <span
          className={classNames('bindable-text-control-entity-label', {
            'bindable-text-control-entity-label--icon-disabled': disableIcon,
            'bindable-text-control-entity-label--chip-disabled': disableChip,
          })}
          onMouseDown={this.handleMouseDown}
          ref={this.entityLabel}
        >
          {label}
          {!(disableIcon || disableChip) ? <Icon type="pencil-small" /> : null}
          {disableChip ? <Icon type="close" onClick={onDelete} /> : null}
        </span>
        {open && !disableChip ? (
          <WrappedEntityDetail
            appId={appId}
            componentId={componentId}
            objectId={objectId}
            role={role}
            reference={reference}
            left={left}
            top={top}
            bottom={bottom}
            entity={entity}
            getLabel={getLabel}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onClose={this.handleClosePopout}
            enableTestValue={enableTestValue}
            fromModal={fromModal}
            hideParentListOptions={hideParentListOptions}
          />
        ) : null}
      </span>
    )
  }
}

const mapStateToProps = (state, { getLabel, entity, appId, componentId }) => {
  let label

  if (getLabel) {
    label = getLabel(entity)
  } else {
    label = getShortLabel(
      state,
      entity.type === 'binding' ? entity.source : entity,
      appId,
      componentId
    )
  }

  return { label }
}

export default connect(mapStateToProps)(BindingEntity)
