import {
  sourceTypes,
  sourceTypeNames,
  selectors,
  dataTypes,
  actionTypes,
} from '@adalo/constants'

import { getCustomAction } from 'ducks/customActions'
import { getApp } from '../../ducks/apps'
import { getDatasource, getCollection } from '../../ducks/apps/datasources'
import { getParam } from '../../ducks/apps/params'
import { selectObject, getCurrentAppId } from '../../ducks/editor/objects'

import { isListEndpoint, getEndpoint, getEndpointLabel } from '../apis'

import { getFieldLabel } from '../tables'
import { AUTHENTICATED, CURRENT } from '../terms'
import { getDateTimeLabel } from '../datetime'
import { singularize, pluralize, tableNamesEqual } from '../strings'
import { getObjectName } from '../naming'
import { getInputDataType } from '../objects'
import { capitalize } from '../type'
import { getLibraryPropLabel } from '../libraries'

//////////////////////////////////////////////////
// CREATING NEW SOURCES
//////////////////////////////////////////////////

// DATA

export const buildTableSource = params => {
  const { datasourceId, tableId, selector, sort } = params

  const obj = {
    type: sourceTypes.DATA,
    dataType: dataTypes.LIST,
    datasourceId,
    tableId,
    source: null,
  }

  if (selector) {
    obj.selector = selector
    obj.dataType = dataTypes.OBJECT
  }

  if (sort) {
    obj.sort = sort
  }

  return obj
}

// ROUTE PARAMS

export const buildRouteParamSource = ({
  datasourceId,
  tableId,
  collectionId,
}) => ({
  type: sourceTypes.DATA,
  dataType: dataTypes.OBJECT,
  datasourceId,
  tableId,
  collectionId,
  selector: {
    type: selectors.ROUTE_PARAM,
  },
})

// CREATED OBJECT

export const buildCreatedObjectSource = opts => {
  const { datasourceId, tableId } = opts

  return {
    type: sourceTypes.DATA,
    dataType: dataTypes.OBJECT,
    datasourceId,
    tableId,
    selector: {
      type: selectors.CREATED_OBJECT,
    },
  }
}

// CUSTOM

export const buildCustomActionSource = opts => {
  const { outputId, key, type, customActionId } = opts

  return {
    type: sourceTypes.CUSTOM_ACTION,
    dataType: type,
    key,
    outputId,
    customActionId,
  }
}

// LIST ITEM

export const buildListItemSource = params => {
  const { listObjectId, datasourceId, tableId, collectionId } = params

  return {
    type: sourceTypes.DATA,
    dataType: dataTypes.OBJECT,
    datasourceId,
    tableId,
    collectionId,
    source: null,
    selector: {
      type: selectors.LIST_ITEM,
      listObjectId,
    },
  }
}

// SELECT MENU

export const buildSelectSource = params => {
  const { datasourceId, tableId, selectObjectId } = params

  return {
    type: sourceTypes.DATA,
    dataType: dataTypes.OBJECT,
    datasourceId,
    tableId,
    selector: {
      type: selectors.SELECT_VALUE,
      selectObjectId,
    },
  }
}

// FIELD

export const buildFieldSource = params => {
  const { fieldId, dataType, source, uri } = params

  return {
    type: sourceTypes.FIELD,
    dataType,
    fieldId,
    source,
    uri,
  }
}

export const buildURLFieldSource = params => {
  return {
    ...buildFieldSource(params),
    isUploadURL: true,
  }
}

// AGGREGATES (count, min, max, sum, etc.)

export const buildAggregateSource = ({ source, type, fieldId }) => ({
  source,
  type,
  fieldId,
  dataType: dataTypes.NUMBER,
})

export const buildCountSource = ({ source }) =>
  buildAggregateSource({
    source,
    type: sourceTypes.COUNT,
  })

// AUTOSAVE INPUTS

export const buildAutosaveSource = (source, value) => ({
  source,
  value,
  type: sourceTypes.AUTOSAVE,
  dataType: source.dataType,
})

////////////////////////////////////////////////
// APIs
////////////////////////////////////////////////

// Base API source type
export const buildAPIEndpointSource = params => {
  const { datasourceId, collectionId, endpointId, endpoint } = params

  return {
    type: sourceTypes.API_ENDPOINT,
    datasourceId,
    collectionId,
    endpointId,
    dataType: isListEndpoint(endpoint) ? dataTypes.LIST : dataTypes.OBJECT,
  }
}

// API Field source
export const buildAPIFieldSource = params => {
  const { source, fieldId, dataType } = params

  return {
    type: sourceTypes.API_FIELD,
    source,
    fieldId,
    dataType,
  }
}

// INPUT (text, date, image)

export const buildInputSource = ({ inputObject, inputId }) => ({
  type: sourceTypes.INPUT,
  dataType: getInputDataType(inputObject),
  objectId: inputId || inputObject.id,
})

export const buildLibraryInputSource = (inputId, prop, dataType) => {
  return {
    dataType,
    type: sourceTypes.INPUT,
    objectId: getLibraryInputId(inputId, prop),
  }
}

const getLibraryInputId = (id, prop) => {
  return `${id}.${prop.join('.')}`
}

// BELONGS-TO

export const buildBelongsToSource = ({ source, tableId, fieldId, multi }) => {
  const { datasourceId } = source

  return {
    type: sourceTypes.BELONGS_TO,
    dataType: multi ? dataTypes.LIST : dataTypes.OBJECT,
    datasourceId,
    tableId,
    fieldId,
    source,
  }
}

// HAS-MANY

export const buildHasManySource = ({ source, tableId, fieldId }) => {
  const { datasourceId } = source

  return {
    type: sourceTypes.HAS_MANY,
    dataType: dataTypes.LIST,
    datasourceId,
    tableId,
    fieldId,
    source,
  }
}

// MANY-TO-MANY

export const buildManyToManySource = opts => {
  const { source, fromTableId, tableId, fieldId } = opts
  const { datasourceId } = source

  return {
    type: sourceTypes.MANY_TO_MANY,
    dataType: dataTypes.LIST,
    datasourceId,
    tableId,
    fromTableId,
    fieldId,
    source,
  }
}

// DATETIME

/**
 *
 * @typedef {import('./types').DateTimeSource} DateTimeSource
 *
 * @param {DateTimeSource.options} options
 * @returns {DateTimeSource}
 */
export const buildDateTimeSource = (options = {}) => {
  if (options.none === true) {
    return {
      type: null,
      dataType: dataTypes.DATE,
      options,
    }
  } else {
    return {
      type: sourceTypes.DATETIME,
      dataType: dataTypes.DATE,
      options,
    }
  }
}

// PARAMS

export const buildParamSource = param => {
  const { id, type } = param

  return {
    type: sourceTypes.PARAM,
    dataType: type.type ? type.type : type,
    paramId: id,
    datasourceId: type.datasourceId,
    tableId: type.tableId,
    collectionId: type.collectionId,
  }
}

// ACTION ARGUMENTS (MAGIC TEXT)

export const buildActionArgumentSource = actionArguments => {
  const { type, actionName, libraryName, i, displayName } = actionArguments

  const excludedDataTypes = [
    dataTypes.LIST,
    dataTypes.LOCATION,
    dataTypes.PASSWORD,
    dataTypes.OBJECT,
    dataTypes.FOREIGN_KEY,
    dataTypes.ACTION_REF,
  ]

  if (excludedDataTypes.includes(type)) return null

  const actionArgumentSource = {
    dataType: type,
    type: sourceTypes.ACTION_ARGUMENT,
    actionName,
    libraryName,
    argumentIndex: i,
    label: displayName,
  }

  return actionArgumentSource
}

// DEVICE LOCATION

export const buildDeviceLocationSource = params => {
  const { dataType, fieldId } = params

  return {
    type: sourceTypes.DEVICE_LOCATION,
    dataType,
    fieldId,
    source: {
      type: sourceTypes.DEVICE_LOCATION,
      dataType: dataTypes.LOCATION,
    },
  }
}

//////////////////////////////////////////////////
// ANALYZING EXISTING SOURCES
//////////////////////////////////////////////////

// ACCESSOR FUNCTIONS

/**
 *
 * @param {*} state
 * @param {*} source
 * @param {string} appId
 * @param {string} componentId
 * @param {boolean} [allowEmpty]
 * @returns {string}
 */
export const getLabel = (state, source, appId, componentId, allowEmpty) => {
  if (source === '' || source === undefined || source === null) {
    return allowEmpty ? '' : 'Empty'
  }

  if (source && source.type === 'binding') {
    source = source.source
  }

  const sourceObj = buildSourceObject(source, appId, componentId)
  let label = sourceObj.getLabel(state)

  if (source.options && source.options.operation) {
    const { operation } = source.options

    if (operation === actionTypes.CREATE_ASSOCIATION) {
      label = `Add ${label}`
    } else if (operation === actionTypes.DELETE_ASSOCIATION) {
      label = `Remove ${label}`
    }
  }

  return label
}

export const getShortLabel = (state, source, appId, componentId) => {
  if (source === '' || source === undefined || source === null) {
    return ''
  }

  if (source && source.type === 'binding') {
    source = source.source
  }

  if (typeof source === 'string') {
    return source
  }

  const sourceObj = buildSourceObject(source, appId, componentId)

  return sourceObj.getShortLabel(state)
}

export const getDatasourceId = source => {
  const sourceObj = buildSourceObject(source)

  return sourceObj.getDatasourceId(source, true)
}

export const getTableId = source => {
  return getTableInfo(source).tableId
}

export const getTableInfo = source => {
  const sourceObj = buildSourceObject(source)

  return {
    datasourceId: sourceObj.getDatasourceId(),
    tableId: sourceObj.getTableId(),
    collectionId: sourceObj.getCollectionId && sourceObj.getCollectionId(),
  }
}

export const getRootSource = source => {
  if (source.source) {
    return getRootSource(source.source)
  }

  return source
}

export const getDataSource = source => {
  if (source.type === sourceTypes.DATA || !source.source) {
    return source
  }

  return source.source
}

export const getRootSourcePath = (source, basePath = '') => {
  if (source.source) {
    const newPath = basePath === '' ? 'source' : `${basePath}.source`

    return getRootSourcePath(source.source, newPath)
  }

  return basePath
}

// CLASSES

class Base {
  constructor(source, appId, componentId) {
    this.source = source
    this.appId = appId
    this.componentId = componentId

    if (source?.source) {
      this.parentSource = buildSourceObject(source.source, appId, componentId)
    }
  }

  getLabel(state) {
    let parentLabel = null
    const label = this.getLabelFragment(state)

    if (this.parentSource) {
      parentLabel = this.parentSource.getLabel(state)

      return `${parentLabel} > ${label}`
    }

    return label
  }

  getShortLabel(state) {
    return this.getLabelFragment(state)
  }

  getLabelFragment(state) {
    throw new Error(
      `getLabelFragment() not implemented on ${this.constructor.name}`
    )
  }

  getDatasourceId(state, allowNull = false) {
    try {
      if (this.parentSource) {
        return this.parentSource.getDatasourceId()
      }

      if (allowNull) {
        return null
      }
    } catch (err) {
      throw new Error(
        `getDatasourceId() not implemented on ${this.constructor.name}`
      )
    }
  }

  getTableId(state) {
    if (this.parentSource) {
      return this.parentSource.getTableId()
    }

    console.warn(`getTableId() not implemented on ${this.constructor.name}`)

    return undefined
  }

  usesCollections() {
    return false
  }
}

class FieldSource extends Base {
  static LOCATION_LABELS = {
    fullAddress: 'LocationFullAddress',
    name: 'LocationName',
    'addressElements.address1': 'LocationStreetAddress',
    'addressElements.city': 'LocationCity',
    'addressElements.region': 'LocationRegion',
    'addressElements.country': 'LocationCountry',
    'addressElements.postalCode': 'LocationPostalCode',
    'coordinates.latitude': 'LocationLatitude',
    'coordinates.longitude': 'LocationLongitude',
  }

  getLabelFragment(state) {
    let table

    if (this.parentSource instanceof TableSource) {
      table = this.parentSource.getTable(state)
    }

    return this.getHumanizedLabel(getFieldLabel(table, this.source.fieldId))
  }

  getHumanizedLabel(fieldLabel) {
    switch (this.parentSource?.source?.dataType) {
      case dataTypes.LOCATION:
        return FieldSource.LOCATION_LABELS[fieldLabel]
      default:
        return fieldLabel
    }
  }

  getShortLabel(state) {
    const table = this.parentSource?.getTable?.(state)
    const deleted = '[Deleted]'

    if (!table || typeof table !== 'object') {
      return this.getLabelFragment(state) || deleted
    }

    const parentLabel = this.parentSource.getTableName
      ? `${this.parentSource.getTableName(state, true)} `
      : ''

    const label = this.getLabelFragment(state).toLowerCase()

    if (String(label).startsWith('c_')) return deleted

    return `${parentLabel}${label}`
  }
}

class AggregateSource extends FieldSource {
  getLabelFragment(state) {
    const superLabel = super.getLabelFragment(state)
    const label = sourceTypeNames[this.source.type]

    return `${superLabel} > ${label}`
  }

  getShortLabel(state) {
    const superLabel = super.getLabelFragment(state)
    const label = sourceTypeNames[this.source.type]

    return `${label} ${superLabel}`
  }
}

class CountSource extends Base {
  getLabelFragment(state) {
    return 'Count'
  }

  getShortLabel(state) {
    const parentLabel = this.parentSource.getTableName
      ? `${this.parentSource.getTableName(state, true)} `
      : ''

    if (parentLabel) {
      return `${parentLabel} count`
    }

    return ''
  }
}

class CustomActionSource extends Base {
  constructor(source, appId, componentId) {
    super(source, appId, componentId)
    this.customActionId = source.customActionId
    this.outputId = source.outputId
  }

  getLabelFragment(state) {
    const customAction = getCustomAction(state, this.appId, this.customActionId)

    if (Object.keys(customAction).length === 0) {
      return
    }

    return `${customAction.body.name} > ${this.getOutputName(customAction)}`
  }

  getOutputName(customAction) {
    const outputs = customAction.body.outputs

    return outputs[this.outputId]?.name
  }
}

class TableSource extends Base {
  constructor(source, appId, componentId) {
    super(source, appId, componentId)
    this.datasourceId = source.datasourceId
    this.tableId = source.tableId
    this.collectionId = source.collectionId
    this.selector = source.selector
    this.filter = source.filter
    this.sort = source.sort
  }

  getLabelFragment(state) {
    const table = this.getTable(state)
    const tableName = (table && table.name) || '[Untitled]'
    const modelName = capitalize(singularize(tableName))

    if (this.selector) {
      if (this.selector.type === selectors.CURRENT_USER) {
        return `${AUTHENTICATED} User`
      } else if (this.selector.type === selectors.CREATED_OBJECT) {
        return `New ${modelName}`
      } else if (this.selector.type === selectors.SELECT_VALUE) {
        return `Selected ${modelName}`
      } else if (
        this.selector.type === selectors.LIST_ITEM ||
        this.selector.type === selectors.ROUTE_PARAM
      ) {
        return `${CURRENT} ${modelName}`
      }

      return modelName
    }

    return `All ${tableName.toLowerCase()}`
  }

  getTable(state) {
    const datasource = getDatasource(state, this.appId, this.datasourceId)

    if (!datasource) {
      return 'Loading...'
    }

    const collections = datasource.tables || datasource.collections
    const table = collections[this.tableId || this.collectionId]

    return table
  }

  getTableName(state, singular = false) {
    const table = this.getTable(state)

    if (typeof table === 'string' || !table) {
      return '[Deleted]'
    }

    if (singular) {
      return capitalize(singularize(table.name))
    }

    return capitalize(table.name)
  }

  getDatasourceId() {
    return this.datasourceId
  }

  getTableId() {
    return this.tableId
  }
}

class BelongsToSource extends TableSource {
  getLabelFragment(state) {
    const table = this.getTable(state)
    const tableName = (table && table.name) || '[Untitled]'

    return this.parentSource.source.dataType === 'list'
      ? pluralize(capitalize(tableName))
      : singularize(capitalize(tableName))
  }
}

class HasManySource extends TableSource {
  getLabelFragment(state) {
    const table = this.getTable(state)
    const tableName = (table && table.name) || '[Untitled]'

    return capitalize(tableName)
  }
}

class ManyToManySource extends TableSource {
  constructor(source, appId, componentId) {
    super(source, appId, componentId)
    this.fieldId = source?.fieldId
    this.fromTableId = source?.fromTableId || source?.source?.tableId
  }

  getFromTable(state) {
    const datasource = getDatasource(state, this.appId, this.datasourceId)

    if (datasource) {
      return datasource?.tables?.[this.fromTableId]
    }
  }

  getLabelFragment(state) {
    let tableName

    const fromTable = this.getFromTable(state)

    let fieldInfo = ''

    if (fromTable && this.fieldId in fromTable.fields) {
      tableName = fromTable?.fields?.[this.fieldId]?.name
    } else {
      const table = this.getTable(state)
      tableName = table?.name || '[Untitled]'

      if (!tableNamesEqual(fromTable?.name, tableName)) {
        const field = table?.fields?.[this.fieldId]

        fieldInfo = field ? ` (${field?.name})` : ''
      }
    }

    return `${capitalize(tableName)}${fieldInfo}`
  }
}

class AutosaveSource extends Base {
  constructor(source, appId, componentId) {
    super(source, appId, componentId)

    if (source.value) {
      this.valueSource = buildSourceObject(source.value, appId, componentId)
    }
  }

  getLabel(state) {
    if (this.valueSource) {
      const parentLabel = this.parentSource.getLabelFragment(state)
      const valueLabel = this.valueSource.getLabel(state)

      return `${parentLabel} includes ${valueLabel}?`
    }

    return this.parentSource.getLabel()
  }

  getShortLabel(state) {
    this.getLabel(state)
  }
}

class APIBase extends Base {
  usesCollections() {
    return true
  }

  getCollectionId() {
    let source = this.source

    do {
      if (source.collectionId) {
        return source.collectionId
      }
    } while ((source = source.source))
  }

  getDatasourceId() {
    return (
      this.source.datasourceId ||
      (this.parentSource.getDatasourceId && this.parentSource.getDatasourceId())
    )
  }

  getCollection(state) {
    const datasourceId = this.getDatasourceId()
    const collectionId = this.getCollectionId()
    const appId = this.appId || getCurrentAppId(state)

    return getCollection(state, appId, datasourceId, collectionId)
  }
}

class APISource extends APIBase {
  getLabelFragment(state) {
    const collection = this.getCollection(state)
    const collectionName = (collection && collection.name) || 'Untitled'
    const modelName = capitalize(singularize(collectionName))

    return `${CURRENT} ${modelName}`
  }
}

class APIEndpointSource extends APISource {
  getLabelFragment(state) {
    const collection = this.getCollection(state)
    const endpoint = this.getEndpoint(state)
    const endpointLabel = getEndpointLabel(endpoint)

    return `${collection.name} > ${endpointLabel}`
  }

  getEndpoint(state) {
    const { endpointId } = this.source
    const collection = this.getCollection(state)

    return getEndpoint(collection, endpointId)
  }

  getFields(state) {
    const { fields } = this.getCollection(state)

    return fields
  }
}

class APIFieldSource extends APIBase {
  getLabelFragment(state) {
    return this.source.fieldId
  }

  getFields(state) {
    let parentFields = []

    if (this.parentSource.getFields) {
      parentFields = this.parentSource.getFields(state)
    } else {
      const collection = this.getCollection(state)
      parentFields = collection.fields
    }

    const field = parentFields.filter(f => f.id === this.source.fieldId)[0]

    return field && field.children
  }
}

class InputSource extends Base {
  getLabelFragment(state) {
    let { objectId } = this.source

    if (!objectId) {
      return null
    }

    if (Array.isArray(objectId)) {
      objectId = objectId[objectId.length - 1]
    }

    const idParts = objectId.split('.')
    const inputObject = selectObject(state, idParts[0])

    if (idParts.length > 1) {
      const app = getApp(state, this.appId)

      return getLibraryPropLabel(app, inputObject, idParts.slice(1))
    }

    return getObjectName(inputObject)
  }
}

class DateTimeSource extends Base {
  getLabelFragment(state) {
    const { options } = this.source
    const { startOfDay, offset } = options

    const defaultLabel = getDateTimeLabel(this.source)

    if (defaultLabel) {
      return defaultLabel
    }

    let label = startOfDay ? 'Start of Day' : 'Current Time'

    if (offset) {
      const sign = offset >= 0 ? '+' : '-'
      const num = Math.abs(offset)
      const unit = num === 1 ? 'day' : 'days'
      label = `${label} (${sign}${num} ${unit})`
    }

    return label
  }
}

class ParamSource extends TableSource {
  getLabelFragment(state) {
    const { paramId } = this.source
    const param = getParam(state, this.appId, this.componentId, paramId)
    let name = param && param.name

    if (!name) {
      name = 'Untitled'
    }

    return `${name}`
  }

  getCollection(state) {
    const { datasourceId, collectionId } = this.source

    return getCollection(state, this.appId, datasourceId, collectionId)
  }
}

class BooleanSource extends Base {
  getLabelFragment() {
    if ([true, 'true'].includes(this.source)) {
      return 'True'
    }

    return 'False'
  }
}

class LiteralSource extends Base {
  getLabelFragment() {
    return `"${String(this.source)}"`
  }
}

class DummySource extends Base {
  getLabel() {
    return ''
  }

  getLabelFragment() {
    return ''
  }

  getFromLabel() {
    return ''
  }
}

class FormulaSource extends Base {
  getLabelFragment() {
    return 'Custom Formula'
  }
}

class FormattedTextSource extends Base {
  constructor(source, appId, componentId) {
    super(source, appId, componentId)

    this.childSources = source.map(itm => {
      if (itm.type === 'binding') {
        itm = itm.source
      }

      return buildSourceObject(itm, appId, componentId)
    })
  }

  getLabelFragment(state) {
    return this.childSources
      .map(src => {
        if (src instanceof LiteralSource) {
          return String(src.source)
        }

        return src.getShortLabel(state)
      })
      .join('')
  }

  getLabel(state) {
    return this.getLabelFragment(state)
  }

  getFromLabel() {
    return ''
  }
}

class ActionArgumentSource extends Base {
  getLabelFragment(state) {
    const { label } = this.source

    return label
  }
}

class ExternalUsersSource extends Base {
  getLabelFragment(state) {
    const { label } = this.source

    return label
  }
}

class DeviceLocationSource extends Base {
  static LOCATION_LABELS = {
    fullAddress: "Device's Full Address",
    name: "Device's Location Name",
    'addressElements.address1': "Device's Street Address",
    'addressElements.city': "Device's City",
    'addressElements.region': "Device's Current State/Region",
    'addressElements.country': "Device's Country",
    'addressElements.postalCode': "Device's Postal Code",
    'coordinates.latitude': "Device's Latitude",
    'coordinates.longitude': "Device's Longitude",
  }

  getLabelFragment() {
    const label = 'Current Device Location'

    if (
      this.parentSource &&
      this.parentSource.source.type === sourceTypes.DEVICE_LOCATION
    ) {
      return DeviceLocationSource.LOCATION_LABELS[this.source.fieldId]
    }

    return label
  }
}

class CustomLocationSource extends Base {
  getLabelFragment(state) {
    const { label } = this.source

    return label
  }
}

export const limitSourceTypes = [
  sourceTypes.MIN,
  sourceTypes.MAX,
  sourceTypes.MIN_MAX,
]

export const cumulativeSourceTypes = [sourceTypes.SUM, sourceTypes.AVERAGE]

export const aggregateSourceTypes = [
  ...cumulativeSourceTypes,
  ...limitSourceTypes,
]

const getSourceClass = source => {
  switch (source && source.type) {
    case sourceTypes.FIELD:
      return FieldSource
    case sourceTypes.COUNT:
      return CountSource
    case sourceTypes.DATA:
      if (source.collectionId) {
        return APISource
      }

      return TableSource
    case sourceTypes.BELONGS_TO:
      return BelongsToSource
    case sourceTypes.HAS_MANY:
      return HasManySource
    case sourceTypes.MANY_TO_MANY:
      return ManyToManySource
    case sourceTypes.INPUT:
      return InputSource
    case sourceTypes.DATETIME:
      return DateTimeSource
    case sourceTypes.PARAM:
      return ParamSource
    case sourceTypes.AUTOSAVE:
      return AutosaveSource
    case sourceTypes.API_ENDPOINT:
      return APIEndpointSource
    case sourceTypes.API_FIELD:
      return APIFieldSource
    case 'formula':
      return FormulaSource
    case sourceTypes.ACTION_ARGUMENT:
      return ActionArgumentSource
    case sourceTypes.CUSTOM_ACTION:
      return CustomActionSource
    case sourceTypes.EXTERNAL_USERS_ID:
      return ExternalUsersSource
    case sourceTypes.EXTERNAL_USERS_TOKEN:
      return ExternalUsersSource
    case sourceTypes.DEVICE_LOCATION:
      return DeviceLocationSource
    case sourceTypes.CUSTOM_LOCATION:
      return CustomLocationSource
  }

  if ([true, false, 'true', 'false'].includes(source)) {
    return BooleanSource
  }

  if (typeof source === 'string') {
    return LiteralSource
  }

  if (aggregateSourceTypes.indexOf(source && source.type) !== -1) {
    return AggregateSource
  }

  if (Array.isArray(source)) {
    return FormattedTextSource
  }

  return null
}

export const buildSourceObject = (source, appId, componentId) => {
  const SourceClass = getSourceClass(source)

  if (!SourceClass) {
    console.error('Invalid source type:', source.type)

    return new DummySource()
  }

  return new SourceClass(source, appId, componentId)
}
