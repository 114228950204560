import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import Button from '../Shared/Button'
import Loading from '../Shared/Loading'
import WizardFooter from './WizardFooter'
import { FORM_NAME } from './shared'
import {
  ensureTemplatesAreLoaded,
  getTemplatesState,
  DEFAULT_TEMPLATES,
} from '../../ducks/apps'
import TemplateSelect from './TemplateSelect'

const Templates = props => {
  const { templates, loading, loaded, primaryPlatform, datasourceType } = props

  if (loading && !loaded) {
    return (
      <div className="new-app-template-loading">
        <TemplateSelect
          options={[
            ...DEFAULT_TEMPLATES[primaryPlatform],
            ...Array(3)
              .fill(null)
              .map((x, index) => ({
                id: `${index}`,
                name: `Template ${index + 1}`,
              })),
          ]}
          primaryPlatform={primaryPlatform}
        />
        <Loading expanded small={false} />
      </div>
    )
  }

  return (
    <Field
      component={TemplateSelect}
      name="templateId"
      options={templates}
      primaryPlatform={primaryPlatform}
      disabled={datasourceType !== 'database'}
    />
  )
}

const WizardFormTemplatesPage = props => {
  const {
    primaryPlatform,
    loading,
    loaded,
    templates,
    handleSubmit,
    handlePreviousPage,
    selectedTemplate,
    datasourceType,
    ensureTemplatesAreLoaded,
    onOpenAdvanced,
  } = props

  useEffect(() => {
    if (primaryPlatform && !loaded) {
      ensureTemplatesAreLoaded(primaryPlatform)
    }
  }, [primaryPlatform, loaded])

  return (
    <>
      <form
        id="new-app-template-form"
        className="new-app-form"
        onSubmit={handleSubmit}
      >
        <Templates
          primaryPlatform={primaryPlatform}
          loading={loading}
          templates={templates}
          datasourceType={datasourceType}
        />
      </form>

      <WizardFooter>
        {handlePreviousPage ? (
          <Button className="previous" onClick={handlePreviousPage}>
            Back
          </Button>
        ) : null}
        <Button className="advanced align-right" onClick={onOpenAdvanced}>
          Advanced Options
        </Button>
        <Button
          className="next"
          type="submit"
          disabled={loading || !selectedTemplate}
          form="new-app-template-form"
          data-adalo-id="new-app-template-form-next"
        >
          Next
        </Button>
      </WizardFooter>
    </>
  )
}

function mapStateToProps(state) {
  const selector = formValueSelector(FORM_NAME)
  let primaryPlatform = selector(state, 'primaryPlatform')
  const mode = primaryPlatform

  if (primaryPlatform === 'mobile') {
    primaryPlatform = 'responsive'
  }

  const templatesState = getTemplatesState(state, primaryPlatform)
  let list = templatesState.list

  if (primaryPlatform === 'responsive') {
    list = list.filter(({ layoutMode }) => !layoutMode || layoutMode === mode)
  }

  return {
    primaryPlatform,
    templates: list,
    loading: templatesState.loading,
    loaded: templatesState.loaded,
    selectedTemplate: selector(state, 'templateId'),
    datasourceType: selector(state, 'datasourceType'),
  }
}

const mapDispatchToProps = {
  ensureTemplatesAreLoaded,
}

export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(WizardFormTemplatesPage))
