import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { sort } from '@adalo/utils'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'

import {
  getSelection,
  getHoverSelection,
  setLayersHover,
} from 'ducks/editor/selection'
import { selectObjects, getPath } from 'ducks/editor/objects'
import { getApp } from 'ducks/apps'
import { getCurrentUser } from 'ducks/users/index.ts'
import { NEW_SCREEN_MODAL, showModal } from 'ducks/editor/modals'

import EmptyState from 'components/Shared/EmptyState'
import Icon, { IconButton } from 'components/Shared/Icon'
import { PanelItem } from 'components/Shared/Panel'

import { getScreenIcon } from 'utils/icons'
import centerScreenOnCanvas from 'utils/objects/centerScreenOnCanvas'
import classNames from 'classnames'

const LayersMenu = ({
  app,
  objects,
  setLayersHover,
  isAdmin,
  history,
  match,
}) => {
  const dispatch = useDispatch()

  const [search, setSearch] = useState('')

  const handleClick = async () => {
    const { appId } = match.params

    const isResponsiveApp = app?.primaryPlatform === 'responsive'

    if (isResponsiveApp) {
      history.push(`/apps/${appId}/screens`)

      return dispatch(showModal(NEW_SCREEN_MODAL, { appId, zoom: true }))
    }

    history.push(`/apps/${appId}/screens/add/screen`)
  }

  const handleSearch = event => setSearch(event.target.value)

  const renderMenu = () => {
    if (!isAdmin) {
      return null
    }

    return (
      <MultiMenuTrigger
        menu={[
          {
            label: (
              <div className="collections-menu-option">
                <Icon type="lock" /> <p>Debug</p>
              </div>
            ),
            onClick: () => {
              const { appId } = match.params

              window.open(`/admin/app-screens/${appId}`, '_blank')
            },
          },
          {
            label: (
              <div className="collections-menu-option">
                <Icon type="help" /> <p>Learn More</p>
              </div>
            ),
            onClick: () => {
              window.open(
                `https://help.adalo.com/component-basics/inserting-and-editing-screens`,
                '_blank'
              )
            },
          },
        ]}
        rowHeight={40}
        width={180}
      >
        <IconButton type="more-vert" />
      </MultiMenuTrigger>
    )
  }

  const renderScreens = () => {
    if (!app || objects.length === 0) {
      return <EmptyState>Nothing drawn yet</EmptyState>
    }

    const { authComponentId, launchComponentId } = app

    // sort objects A-Z by name
    const sorted = sort(objects, obj => obj.name)

    const results = search
      ? sorted.filter(object =>
          object.name.toLowerCase().includes(search.toLowerCase())
        )
      : sorted

    if (search && results.length === 0) {
      return <EmptyState>No screens found</EmptyState>
    }

    return results.map(object => {
      const { id, name } = object

      const icon = getScreenIcon(id, authComponentId, launchComponentId)
      const handleMouseEnter = () => setLayersHover(object)
      const handleMouseLeave = () => setLayersHover(null)
      const handleClick = () => {
        dispatch(centerScreenOnCanvas(object))
      }

      return (
        <PanelItem
          key={id}
          content={name}
          title={name}
          icon={icon}
          fluid
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
        />
      )
    })
  }

  return (
    <div className="layers-wrapper">
      <h2 className="left-panel-title">
        <Icon type="screens" />
        <span className="title">Screens</span>
        {renderMenu()}
      </h2>
      <div
        className={classNames('editor-add-panel-search', {
          disabled: !app || objects.length === 0,
        })}
      >
        <i className="material-icons">search</i>
        <input
          placeholder="Search screens..."
          value={search}
          onChange={handleSearch}
        />
      </div>

      {renderScreens()}

      <PanelItem
        title="Add Screen"
        icon="plus"
        color="screens"
        uppercase
        fluid
        onClick={() => handleClick()}
      />
    </div>
  )
}

const mapStateToProps = (state, { appId }) => {
  const currentUser = getCurrentUser(state)

  return {
    app: getApp(state, appId),
    objects: selectObjects(state),
    selection: getSelection(state).map(id => getPath(state, id)),
    hoverSelection: getHoverSelection(state).map(id => getPath(state, id)),
    isAdmin: Boolean(currentUser && currentUser.admin),
  }
}

const mapDispatchToProps = {
  setLayersHover,
}

const ConnectedLayersMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(LayersMenu)

export default withRouter(ConnectedLayersMenu)
