import React, { useState, useEffect } from 'react'
import { reduxForm, formValueSelector, change } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  getTemplateBrandingState,
  templateIdIsBlankType,
  getDefaultTemplateIdForResponsive,
} from 'ducks/apps'
import { fetchOrganizations, getOrganizations } from 'ducks/organizations'
import { getCurrentUser } from 'ducks/users'

import { defaultBranding } from 'utils/colors'
import { createApp } from 'utils/io'
import { getNewAppWebSettings } from 'utils/webSettings'

import Stepper from 'components/Shared/Stepper'
import WizardFormPlatformPage from './WizardFormPlatformPage'
import WizardFormBrandingPage from './WizardFormBrandingPage'
import WizardFormAdvancedPage from './WizardFormAdvancedPage'
import './NewApp.css'
import { FORM_NAME } from './shared'
import WizardFormTemplatesPage from './WizardFormTemplatesPage'

const WizardFormStepper = ({ page }) => {
  switch (page) {
    case 'advanced':
      return null
    default:
      return (
        <Stepper
          steps={['Platform', 'Template', 'Branding']}
          currentStep={page - 1}
        />
      )
  }
}

const WizardForm = ({
  fetchOrganizations,
  organizations,
  currentUser,
  datasourceType,
  templateId,
  change,
  branding,
}) => {
  const [page, setPage] = useState(1)

  useEffect(() => {
    fetchOrganizations()
  }, [fetchOrganizations])

  const handleNextPage = () => {
    setPage(prevPage => (typeof prevPage === 'number' ? prevPage + 1 : 1))
  }

  const handlePreviousPage = () => {
    setPage(prevPage => (typeof prevPage === 'number' ? prevPage - 1 : 1))
  }

  const handleOpenAdvanced = () => {
    setPage('advanced')
  }

  const handleSubmitAdvanced = ({ primaryPlatform }) => {
    if (!templateId) {
      change(
        FORM_NAME,
        'templateId',
        getDefaultTemplateIdForResponsive(primaryPlatform)
      )
    }

    if (datasourceType === 'database') {
      setPage(2)
    } else {
      if (!templateIdIsBlankType(templateId)) {
        change(
          FORM_NAME,
          'templateId',
          getDefaultTemplateIdForResponsive(primaryPlatform)
        )
      }
      setPage(3)
    }
  }

  const handleFinalSubmit = async ({
    primaryColor,
    secondaryColor,
    ...data
  }) => {
    data.datasourceAppId =
      data.datasourceType === 'copy' ? data.datasourceAppId : undefined

    data.branding = {
      ...defaultBranding,
      primary: primaryColor,
      secondary: secondaryColor,
    }

    if (!data.organizationId) {
      data.organizationId = organizations[0] && organizations[0].id
    }

    if (!data.organizationId) {
      return window.alert('Error: You are not part of any teams')
    }

    data.type = data.appUsage
    delete data.appUsage

    if (currentUser?.persona === 'Freelancer/Agency') {
      data.builtWithFreelancer = true
    }

    data.webSettings = getNewAppWebSettings({
      primaryPlatform: data.primaryPlatform,
      mobileOnly: data.primaryPlatform === 'mobile',
    })

    if (data.primaryPlatform === 'mobile') {
      data.primaryPlatform = 'responsive'
      data.webSettings = {
        layoutMode: 'mobile',
        ...data.webSettings,
      }
    } else {
      data.webSettings = {
        layoutMode: 'responsive',
        ...data.webSettings,
      }
    }

    return createApp(data)
  }

  const renderPage = () => {
    const initialValues = {
      datasourceType: 'database',
      // TODO(enzo): why is magicLayout false by default?
      magicLayout: false,
      primaryColor: branding && branding.primary,
      secondaryColor: branding && branding.secondary,
    }

    switch (page) {
      case 1:
        return (
          <WizardFormPlatformPage
            initialValues={initialValues}
            onSubmit={handleNextPage}
          />
        )
      case 2:
        return (
          <WizardFormTemplatesPage
            handlePreviousPage={handlePreviousPage}
            initialValues={initialValues}
            onSubmit={handleNextPage}
            onOpenAdvanced={handleOpenAdvanced}
          />
        )
      case 3:
        return (
          <WizardFormBrandingPage
            handlePreviousPage={handlePreviousPage}
            initialValues={initialValues}
            onSubmit={handleFinalSubmit}
          />
        )
      case 'advanced':
        return (
          <WizardFormAdvancedPage
            initialValues={initialValues}
            onSubmit={handleSubmitAdvanced}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <WizardFormStepper page={page} />
      {renderPage()}
      <WizardFormDestroyer />
    </>
  )
}

WizardForm.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  datasourceType: PropTypes.string,
  templateId: PropTypes.string,
  change: PropTypes.func.isRequired,
  branding: PropTypes.object,
}

const WizardFormDestroyer = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(() => null)

const mapStateToProps = state => {
  const selector = formValueSelector(FORM_NAME)
  const templateId = selector(state, 'templateId')
  const branding = templateIdIsBlankType(templateId)
    ? defaultBranding
    : getTemplateBrandingState(state, templateId).branding

  return {
    organizations: getOrganizations(state),
    templateId,
    branding,
    datasourceType: selector(state, 'datasourceType'),
    currentUser: getCurrentUser(state),
  }
}

const mapDispatchToProps = {
  fetchOrganizations,
  change,
}

export default connect(mapStateToProps, mapDispatchToProps)(WizardForm)
