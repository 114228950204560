import React, { useEffect, useState } from 'react'
import { reduxForm, Field } from 'redux-form'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { adminUpdatePassword } from 'utils/io'
import WrappedInput from 'components/Shared/Forms/WrappedInput'
import Button from '../Shared/Button'

import { requestUser, getUser } from '../../ducks/admin/users'
import EmptyState from '../Shared/EmptyState'
import Loading from '../Shared/Loading'
import Page from '../Shared/Page'
import AppIcon from '../Shared/AppIcon'

import './Admin.scss'
import { adaloBackendAxios } from '../../utils/io/http/axios'

const FORM_NAME = 'adminResetPasswordForm'

const AppItem = ({ app }) => {
  const [datasources, setDatasources] = useState([])
  const [loadingError, setLoadingError] = useState(null)

  useEffect(() => {
    Promise.all([adaloBackendAxios.get(`/apps/${app.id}/datasources`)])
      .then(([result]) => {
        const dbs = Object.keys(result.data)
        setDatasources(dbs)
      })
      .catch(err => {
        setLoadingError(err)
      })
  }, [])

  if (loadingError) {
    alert(loadingError)
  }

  return (
    <Link className="admin-user-item" to={`/apps/${app.id}`}>
      <AppIcon app={app} />
      <h5>{app.name}</h5>
      <p>{app.createdAt}</p>
      <p>{app.updatedAt}</p>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {datasources.map(id => (
          <Link to={`/admin/datasource/${id}`}>{id}</Link>
        ))}
      </div>
    </Link>
  )
}

export const AdminUserOrganization = ({ organization }) => {
  const orgStatus = organization.active ? 'paying' : 'free'

  return (
    <span className="admin-user-organization">
      <span className="admin-user-org-sub">
        {organization.name}
        <span
          className={classNames('admin-org-status', `admin-org-${orgStatus}`)}
        >
          {orgStatus}
        </span>
      </span>
    </span>
  )
}

const AdminApps = ({ match }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(requestUser(match.params.userId))
  }, [])

  const user = useSelector(state => getUser(state, match.params.userId))

  if (!user) {
    return (
      <EmptyState greedy>
        <Loading large />
      </EmptyState>
    )
  }

  const apps = user.Apps

  return (
    <Page className="admin-users-page" title="Users">
      <Link to="/admin/users">Back to Users</Link>
      <div>
        <h1>{user.name}</h1>
        <h2>{user.email}</h2>
      </div>
      <ChangePasswordForm userId={user.id} admin={user.admin} />
      <h3>
        {user.Organizations.map(org => (
          <AdminUserOrganization key={org.id} organization={org} />
        ))}
      </h3>
      <div>
        {apps.map(app => (
          <AppItem key={app.id} app={app} />
        ))}
      </div>
    </Page>
  )
}

const ChangePasswordForm = ({ userId, admin }) => {
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState(null)

  if (!admin) {
    return null
  }

  const verifyPasswordChange = async e => {
    e.preventDefault()

    if (
      window.confirm(`Are you sure you want to reset this user's password?`)
    ) {
      if (message) {
        setMessage(null)
      }

      try {
        await adminUpdatePassword({ password, userId })

        setPassword('')
        setMessage('Successfully reset the password!')
      } catch (err) {
        console.error(`Could not update password: ${err.message}`)
        setMessage(err.message)
      }
    }
  }

  return (
    <>
      <form
        onSubmit={verifyPasswordChange}
        className="admin-reset-password-form"
      >
        <input
          type="text"
          name="email"
          autoComplete="username email"
          style={{ display: 'none' }}
        />
        <Field
          autoFocus
          name="password"
          placeholder="New Password"
          autoComplete="new-password"
          type="password"
          label="New Password"
          className="admin-password-field"
          maxLength={200}
          onChange={e => setPassword(e.target.value)}
          component={WrappedInput}
          value={password}
        />
        <Button large yellow>
          Change Password
        </Button>
      </form>
      {message ? (
        <span className="admin-password-message">{message}</span>
      ) : null}
    </>
  )
}

export default reduxForm({
  form: FORM_NAME,
})(AdminApps)
