import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  positioning,
  positioningOptions,
  inputTypes,
  inputTypeOptions,
  statusBarStyleOptions,
  imageResize,
  imageResizeOptions,
  COMPONENT,
} from '@adalo/constants'

import ToggleButton from 'components/Shared/Forms/ToggleButton'

import { getApp, getAppBranding } from 'ducks/apps'

import {
  features,
  commonValues,
  OBJECTS_SUPPORTING_BORDER_RADIUS_CONTROL,
} from 'utils/objects'
import { fontWeightOptions, getFontFamilyOptions } from 'utils/type'
import getDeviceObjectFromState from 'utils/getDeviceObjectFromState'
import { getMap, getObjectList, updateObjects } from 'ducks/editor/objects'

import InspectRow from './Row'
import SingleNumberControl from './SingleNumberControl'
import TextControl from './TextControl'
import TextAlignControl from './TextAlignControl'
import OptionControl from './OptionControl'
import SelectControl from './SelectControl'
import ImageControl from './ImageControl'
import ListTypeControl from './ListTypeControl'
import SliderControl from './Libraries/SliderControl'
import BorderControl from './BorderControl'
import { ShadowControl as DefaultShadowControl } from './DefaultShadowControl'
import BackgroundControl from './BackgroundControl'
import { FontControl } from './DefaultFontControl'
import GenericInspectRow from './GenericRow'
import { SizeControl } from './DefaultSizeControl'
import MenuControl from './Libraries/MenuControl'
import BackgroundImageControl from './BackgroundImageControl'
import ColorControl from './Libraries/ColorControl'
import { TableDataStyles } from './TableDataStyles'

class InspectBody extends Component {
  handleChange = value => {
    const { objects, updateObjects } = this.props

    const newObjects = objects.map(({ id }) => ({ ...value, id }))

    updateObjects(newObjects)
  }

  getMaxBorderRadius = object => {
    const width = object.width || 100
    const height = object.height || 100

    const min = Math.min(width, height)

    return Math.floor(min / 2)
  }

  render() {
    const {
      appId,
      objects,
      branding,
      magicLayout,
      getStateDeviceObject,
      featuresSubset,
    } = this.props

    const objectFeatures = commonValues(
      objects.map(obj => {
        if (
          typeof featuresSubset === 'string' &&
          typeof features[obj.type][featuresSubset] === 'object'
        ) {
          return features[obj.type][featuresSubset]
        }

        return features[obj.type]
      })
    )

    const object = commonValues(objects, getStateDeviceObject)

    const hideBorderRadius = OBJECTS_SUPPORTING_BORDER_RADIUS_CONTROL.has(
      object?.type
    )

    // TODO @danicunhac: refactor this, it's a mess.
    return (
      <div>
        {!magicLayout && objectFeatures.positioning ? (
          <InspectRow title="Fixed">
            <OptionControl
              name="positioning"
              value={object.positioning}
              defaultValue={positioning.DEFAULT}
              onChange={this.handleChange}
              options={positioningOptions}
            />
          </InspectRow>
        ) : null}
        {objectFeatures.width && objectFeatures.height && (
          <SizeControl object={object} onChange={this.handleChange} />
        )}
        {objectFeatures.text && (
          <InspectRow bindable title="Text">
            <TextControl
              name="text"
              value={object.text}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {objectFeatures.filename1x && (
          <InspectRow title="Image">
            <ImageControl
              name="filename1x"
              value={object.filename1x}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {objectFeatures.inputType && (
          <InspectRow title="Input Type">
            <SelectControl
              name="inputType"
              defaultValue={inputTypes.DEFAULT}
              onChange={this.handleChange}
              options={inputTypeOptions}
              value={object.inputType}
            />
          </InspectRow>
        )}
        {objectFeatures.placeholder && (
          <InspectRow title="Placeholder">
            <TextControl
              name="placeholder"
              value={object.placeholder}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {objectFeatures.defaultValue && (
          <InspectRow title="Default Value">
            <TextControl
              name="defaultValue"
              value={object.defaultValue}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {objectFeatures.maxLength && (
          <GenericInspectRow
            title="Max Length"
            className="default-padding-control"
          >
            <TextControl
              gray
              type="number"
              name="maxLength"
              value={object.maxLength}
              onChange={this.handleChange}
              placeholder="None"
            />
          </GenericInspectRow>
        )}
        {objectFeatures.autoFocus && (
          <GenericInspectRow title="Auto-focus" className="toggle-button-block">
            <div className="toggle-button-block-box">
              {object.autoFocus ? 'On' : 'Off'}
              <ToggleButton
                value={object.autoFocus}
                onChange={() => {
                  this.handleChange({
                    autoFocus: !object.autoFocus,
                  })
                }}
              />
            </div>
          </GenericInspectRow>
        )}
        {objectFeatures.padding && (
          <GenericInspectRow
            className="default-padding-control"
            title="Padding"
          >
            <TextControl
              gray
              type="number"
              name="padding"
              value={object.padding}
              onChange={this.handleChange}
            />
          </GenericInspectRow>
        )}
        {(objectFeatures.fontSize ||
          objectFeatures.fontWeight ||
          objectFeatures.color ||
          objectFeatures.fontFamily ||
          objectFeatures.placeholderColor) && (
          <FontControl
            object={object}
            onChange={this.handleChange}
            features={objectFeatures}
            fontFamilyOptions={getFontFamilyOptions(branding)}
            fontWeightOptions={fontWeightOptions}
          />
        )}
        {objectFeatures.imageResize && (
          <InspectRow title="Resize">
            <OptionControl
              name="imageResize"
              value={object.imageResize}
              defaultValue={imageResize.COVER}
              onChange={this.handleChange}
              options={imageResizeOptions}
            />
          </InspectRow>
        )}
        {(objectFeatures.backgroundStyle || objectFeatures.backgroundColor) && (
          <>
            {object.type !== COMPONENT && (
              <BackgroundControl
                key="background-control"
                appId={appId}
                object={object}
                boxedToggle
              />
            )}
            {object.type === COMPONENT && [
              <GenericInspectRow className="background-color-control">
                <ColorControl
                  value={object.backgroundColor}
                  name="backgroundColor"
                  onChange={this.handleChange}
                  label="Background Color"
                />
              </GenericInspectRow>,
              <BackgroundImageControl
                object={object}
                onChange={this.handleChange}
                appId={appId}
              />,
            ]}
          </>
        )}
        {objectFeatures.tableStyles && (
          <TableDataStyles
            object={object}
            branding={branding}
            onChange={this.handleChange}
          />
        )}
        {objectFeatures.borderStyle && (
          <BorderControl
            key="border-control"
            appId={appId}
            object={object}
            boxedToggle
          />
        )}
        {objectFeatures.multiline && (
          <GenericInspectRow title="Multi-line" className="toggle-button-block">
            <div className="toggle-button-block-box">
              {object.multiline ? 'On' : 'Off'}
              <ToggleButton
                value={object.multiline}
                onChange={() => {
                  this.handleChange({
                    multiline: !object.multiline,
                  })
                }}
              />
            </div>
          </GenericInspectRow>
        )}
        {objectFeatures.selectable && (
          <GenericInspectRow title="Copyable" className="toggle-button-block">
            <div className="toggle-button-block-box">
              {object.selectable ? 'On' : 'Off'}
              <ToggleButton
                value={object.selectable}
                onChange={() => {
                  this.handleChange({
                    selectable: !object.selectable,
                  })
                }}
              />
            </div>
          </GenericInspectRow>
        )}
        {objectFeatures.opacity && (
          <SliderControl
            sliderBackground
            onChange={this.handleChange}
            value={object.opacity}
            name="opacity"
            max={1}
            min={0}
            defaultValue={1}
            percentage
            title="Opacity"
          />
        )}
        {objectFeatures.masonry && (
          <GenericInspectRow
            title="Masonry Layout"
            className="toggle-button-block"
          >
            <div className="toggle-button-block-box">
              {object.masonry ? 'On' : 'Off'}
              <ToggleButton
                value={object.masonry}
                onChange={() => {
                  this.handleChange({
                    masonry: !object.masonry,
                  })
                }}
              />
            </div>
          </GenericInspectRow>
        )}
        {!hideBorderRadius && objectFeatures.borderRadius && (
          <SliderControl
            sliderBackground
            onChange={this.handleChange}
            value={object.borderRadius}
            name="borderRadius"
            max={this.getMaxBorderRadius(object)}
            min={0}
            defaultValue={0}
            title="Rounding"
          />
        )}
        {objectFeatures.textAlignment && (
          <InspectRow title="Alignment">
            <TextAlignControl
              name="textAlignment"
              value={object.textAlignment}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {objectFeatures.autoWidth && (
          <GenericInspectRow title="Auto-width" className="toggle-button-block">
            <div className="toggle-button-block-box">
              {object.autoWidth ? 'On' : 'Off'}
              <ToggleButton
                value={object.autoWidth}
                onChange={() => {
                  this.handleChange({
                    autoWidth: !object.autoWidth,
                  })
                }}
              />
            </div>
          </GenericInspectRow>
        )}
        {objectFeatures.shadow && (
          <DefaultShadowControl object={object} onChange={this.handleChange} />
        )}
        {objectFeatures.statusBarStyle && (
          <MenuControl
            displayName="Status Bar"
            name="statusBarStyle"
            value={object.statusBarStyle}
            onChange={this.handleChange}
            options={statusBarStyleOptions}
            placeholder="Auto"
            className="font-family-control"
          />
        )}
        {objectFeatures.reverseScroll && (
          <GenericInspectRow
            title="Reverse-scroll"
            className="toggle-button-block"
          >
            <div className="toggle-button-block-box">
              {object.reverseScroll ? 'On' : 'Off'}
              <ToggleButton
                value={object.reverseScroll}
                onChange={() => {
                  this.handleChange({
                    reverseScroll: !object.reverseScroll,
                  })
                }}
              />
            </div>
          </GenericInspectRow>
        )}
        {objectFeatures.uri && (
          <InspectRow title="URL">
            <TextControl
              name="uri"
              value={object.uri}
              onChange={this.handleChange}
              placeholder="https://example.com"
            />
          </InspectRow>
        )}
        {objectFeatures.listType && (
          <InspectRow title="List Type">
            <ListTypeControl
              name="listType"
              value={object.listType}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
        {objectFeatures.rowMargin && (
          <InspectRow title="Spacing">
            <SingleNumberControl
              name="rowMargin"
              value={object.rowMargin}
              onChange={this.handleChange}
            />
          </InspectRow>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, { match }) => {
  const { appId } = match.params
  const app = getApp(state, appId)
  const branding = getAppBranding(state, appId)
  const map = getMap(state)
  const list = getObjectList(state)
  const getStateDeviceObject = obj => getDeviceObjectFromState(list, map, obj)

  return {
    appId,
    branding,
    magicLayout: app?.magicLayout,
    getStateDeviceObject,
  }
}

export const DisconnectedInspectBody = InspectBody

export default withRouter(
  connect(mapStateToProps, { updateObjects })(InspectBody)
)
